import { AsyncValidatorFn, ValidatorFn } from '@angular/forms';

export interface FormModel {
  name: string;
  validators: ValidatorFn[];
  asyncValidatorFn: AsyncValidatorFn[];
  defaultValue?: unknown;
  defaultDisabled?: boolean;
  children?: FormModel[];
  subGroup?: FormModel[];
  suggested?: boolean; // mobile purposes
}
