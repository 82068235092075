import { Component, Input, OnInit } from '@angular/core';
import { faCheck, faClock, faExclamation, faExclamationTriangle, faTimes } from '@fortawesome/free-solid-svg-icons';
import { EntryComponentContentTypes } from '../../domain/table.model';
import { isNil } from '@foxeet/utils/*';
import { AppraiserStates } from '@foxeet/domain';

@Component({
  selector: 'core-status-badges',
  templateUrl: './status-badges.component.html',
  styleUrls: ['./status-badges.component.scss'],
})
export class StatusBadgesComponent implements OnInit {
  @Input() data: any = {};
  @Input() contentType: EntryComponentContentTypes;
  @Input() entryComponentType: 'appraisal' | 'request';

  public showIncidenceBag: boolean = false;
  public showCancelledBag: boolean = false;
  public showClaimBag: boolean = false;
  public urlLink: string = '';
  public badges: any[] = [];

  ngOnInit() {
    this.setBadges();
  }

  setBadges() {
    switch (this.contentType) {
      case EntryComponentContentTypes.APPRAISAL:
        this.setShowCancelledBag();
        this.setShowClaimBag();
        this.setShowIncidenceBag();
        break;
      case EntryComponentContentTypes.REQUESTS:
        this.setShowCancelledBag();
        this.setShowClaimBag();
        this.setShowIncidenceBag();
        this.setConfirmExpensesBag();

        break;
      case EntryComponentContentTypes.USERS:
        this.setShowAppraiserStatusBag();
        break;
      default:
        break;
    }
  }

  setIsVisitScheduleDeadlineExpiredBadge() {
    if (this.data?.isVisitScheduleDeadlineExpired && !this.data?.isAppraiserAllDataSentDeadlineExpired && !this.data?.isCustomerDeadlineExpired) {
      this.badges.push({
        label: 'BADGE_visitScheduleDeadlineExpiredLabel',
        icon: faExclamationTriangle,
        class: 'warning-badge',
      });
    }
  }

  setIsAppraiserAllDataSentDeadlineExpired() {
    if (this.data?.isAppraiserAllDataSentDeadlineExpired && !this.data?.isCustomerDeadlineExpired) {
      this.badges.push({
        label: 'BADGE_appraiserAllDataSentDeadlineExpiredLbel',
        icon: faExclamationTriangle,
        class: 'warning-badge',
      });
    }
  }

  setIsCustomerDeadlineExpired() {
    if (this.data?.isCustomerDeadlineExpired) {
      this.badges.push({
        label: 'BADGE_customerDeadlineExpiredLabel',
        icon: faExclamationTriangle,
        class: 'danger-badge',
      });
    }
  }

  setShowIncidenceBag() {
    if (!this.data?.isCancelled && this.data?.hasIncidentsNotResolved) {
      this.badges.push({
        label: 'BADGE_withIncidentsLabel',
        text: 'INC',
        class: 'danger-badge',
      });
    }
  }

  setShowClaimBag() {
    if (!this.data?.isCancelled && this.data?.withClaimsNotResolved) {
      this.badges.push({
        label: 'BADGE_withClaimsLabel',
        text: 'RCL',
        class: 'claim-badge',
      });
    }
  }

  setShowCancelledBag() {
    if (this.data?.isCancelled) {
      this.badges.push({
        label: 'BADGE_cancelledLabel',
        text: 'ANU',
        class: 'metal-badge',
      });
    }
  }

  setShowAppraiserStatusBag() {
    if (!isNil(this.data?.appraiserState)) {
      switch (this.data?.appraiserState) {
        case AppraiserStates.Active:
          this.badges.push({
            label: 'USERS_state_checked',
            icon: faCheck,
            class: 'success-badge',
          });
          break;
        case AppraiserStates.NotValidated:
          this.badges.push({
            label: 'USERS_state_notValidated',
            icon: faExclamation,
            class: 'warning-badge',
          });
          break;
        case AppraiserStates.Absent:
          this.badges.push({
            label: 'USERS_state_absent',
            icon: faClock,
            class: 'metal-badge',
          });
          break;
        case AppraiserStates.Inactive:
          this.badges.push({
            label: 'USERS_state_inactive',
            icon: faTimes,
            class: 'danger-badge',
          });
          break;
        default:
          break;
      }
    }
  }
  setConfirmExpensesBag() {
    if (!isNil(this.data?.withOpportunityId) && !isNil(this.data?.isCustomerPendingConfirmEntityType)) {
      if ((!this.data?.withOpportunityId || this.data?.isCustomerPendingConfirmEntityType) && !this.data?.isCancelled) {
        this.badges.push({
          label: 'REQUESTS_confirmExpensesIconTooltip',
          matIcon: 'feedback',
          class: 'transparent-warning',
        });
      }
    }
  }
}
