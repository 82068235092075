import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  AppraisalEditModel,
  AppraisalIndexModel,
  FoxeetKeyValueModel,
  GenericTableData,
  KeyValueModel,
  OrderRequestDashboardFilterModel,
  OrderRequestDashboardModel,
  OrderRequestElementModel,
  OrderRequestFileIndexModel,
  OrderRequestInvoiceDataEditModel,
  OrderRequestInvoiceIndexModel,
  OrderRequestInvoiceSummary,
  OrderRequestModel,
  OrderRequestPaymentIndexModel,
  OrderRequestPriceEditModel,
  OrderRequestPriceModel,
  OrderRequestPromotionalCodeModel,
  OrderRequestProvisionEditModel,
  RequestsWallCommentModel,
  WorkflowItemExternalCommunicationProcessModel,
} from '@foxeet/domain';
import { Observable } from 'rxjs';
import { CacheService } from '../services/cache.service';
import { removeNullsAndUndefined } from '../utils/data-access.utils';
import { WorkflowService } from '../services/workflow.service';

// TODO: Review models Appraisal (?)
@Injectable()
export class RequestsService extends WorkflowService {
  constructor(http: HttpClient, cache: CacheService) {
    super(http, 'OrderRequests', cache);
  }

  cancel(id: number, description: string): Observable<void> {
    const body = removeNullsAndUndefined({ id, description });
    return this.put<typeof body, void>(`${id}/Cancel`, body);
  }

  finalize(id: number): Observable<void> {
    return this.put(`${id}/Finalize`, null);
  }

  setToOrderGeneratedManually(id: number): Observable<void> {
    return this.put(`${id}/AcceptedBudgetManually`, null);
  }

  resendPaymentEmail(id: number): Observable<void> {
    return this.post<null, void>(`${id}/ResendPaymentEmail`, null);
  }

  getAllDocuments(orderId: number): Observable<OrderRequestFileIndexModel[]> {
    return this.get<OrderRequestFileIndexModel[]>(`${orderId}/Files`);
  }

  getVoucher(promotionalCode: string): Observable<HttpResponse<OrderRequestPromotionalCodeModel>> {
    return this.http.get<OrderRequestPromotionalCodeModel>(`${this.apiBranch}/Voucher`, {
      params: this.setParams({ promotionalCode }),
      observe: 'response',
    });
  }

  calculateOrderPrice(body: OrderRequestPriceEditModel): Observable<HttpResponse<OrderRequestPriceModel>> {
    const data = removeNullsAndUndefined(body);
    return this.http.post<OrderRequestPriceModel>(`${this.apiBranch}/CalculateOrderPrice`, data, { observe: 'response' });
  }

  getPaymentData(page: number, pagesize: number, data: any): Observable<HttpResponse<GenericTableData<OrderRequestPaymentIndexModel>>> {
    return this.http.post<GenericTableData<OrderRequestPaymentIndexModel>>(`${this.apiBranch}/${data.orderRequestId}/PaymentsListPaged`, data, {
      observe: 'response',
      params: this.setParams({
        page,
        pagesize,
      }),
    });
  }

  getOrderRequestElementsById(id: number): Observable<OrderRequestElementModel[]> {
    return this.get<OrderRequestElementModel[]>(`${id}/Elements`);
  }

  getModelToCreateNewAppraisalFromAnOrderRequest(orderRequestId: number, orderRequestElementIds: number[]): Observable<AppraisalEditModel> {
    const body = orderRequestElementIds;
    return this.post<typeof body, AppraisalEditModel>(`${orderRequestId}/Appraisal`, body);
  }

  getAppraisalsToInvoiceListPaged(orderRequestId: number, page: number, pagesize: number, data: any) {
    return this.http.post<GenericTableData<AppraisalIndexModel>>(`${this.apiBranch}/${orderRequestId}/AppraisalsToInvoiceListPaged`, data, {
      observe: 'response',
      params: this.setParams({
        page,
        pagesize,
      }),
    });
  }

  getInvoicesListPAged(orderRequestId: number, page: number, pagesize: number, data: any) {
    return this.http.post<GenericTableData<OrderRequestInvoiceIndexModel>>(`${this.apiBranch}/${orderRequestId}/InvoicesListPaged`, data, {
      observe: 'response',
      params: this.setParams({
        page,
        pagesize,
      }),
    });
  }

  getInvoiceSummaryByOrderRequestId(orderRequestId: number) {
    return this.get<OrderRequestInvoiceSummary>(`${orderRequestId}/InvoiceSummary`);
  }

  finishManually(orderRequestId: number) {
    return this.put<null, void>(`${orderRequestId}/Finish`, null);
  }

  getGeneralSellConditionsDocument(): Observable<string> {
    return this.get(`GeneralSellConditionsDocument`, { responseType: 'text' });
  }

  editBillingData(body: OrderRequestInvoiceDataEditModel): Observable<void> {
    return this.put<OrderRequestInvoiceDataEditModel, void>(`${body.id}/InvoiceData`, body);
  }

  public getExternalCommunicationsProcessess(id: number): Observable<WorkflowItemExternalCommunicationProcessModel[]> {
    return this.get<WorkflowItemExternalCommunicationProcessModel[]>(`${id}/ExternalCommunicationsProcessess`);
  }

  public getRequestsExtraInformationPaged(page: number, pageSize: number, id: number): Observable<HttpResponse<GenericTableData<FoxeetKeyValueModel>>> {
    return this.http.post<GenericTableData<FoxeetKeyValueModel>>(
      `${this.apiBranch}/${id}/KeyValues/ListPaged`,
      {},
      {
        observe: 'response',
        params: this.setParams({
          page,
          pageSize,
          id,
        }),
      },
    );
  }

  public getRequestElementExtraInformationPaged(
    page: number,
    pageSize: number,
    id: number,
    requestElementId: number,
  ): Observable<HttpResponse<GenericTableData<FoxeetKeyValueModel>>> {
    return this.http.post<GenericTableData<FoxeetKeyValueModel>>(
      `${this.apiBranch}/${id}/RequestElements/${requestElementId}/KeyValues/ListPaged`,
      {},
      {
        observe: 'response',
        params: this.setParams({
          page,
          pageSize,
          id,
          requestElementId,
        }),
      },
    );
  }

  getRequestAllowedKeys(id: number, licenseeId: number | undefined) {
    const url = `${id}/KeyValues/AllowedToCreate`;
    return this.get<KeyValueModel[]>(url, { observe: 'response', params: this.setParams({ licenseeId }) });
  }

  getRequestElementAllowedKeys(id: number, requestElementId: number, licenseeId: number | undefined) {
    const url = `${id}/RequestElements/${requestElementId}/KeyValues/AllowedToCreate`;
    return this.get<any>(url, { observe: 'response', params: this.setParams({ licenseeId }) });
  }

  addNewDataToRequest(id: number, body: any) {
    return this.post<unknown, number>(`${id}/KeyValues`, body, {
      params: this.setParams({ id }),
    });
  }

  addNewDataToRequestElement(id: number, requestElementId: number, body: any) {
    return this.post<unknown, number>(`${id}/RequestElements/${requestElementId}/KeyValues`, body, {
      params: this.setParams({ id, requestElementId }),
    });
  }

  removeDataAppraisal(requestId: number, keyValueId: number) {
    return this.delete(`${requestId}/KeyValues/${keyValueId}`);
  }

  removeDataAppraisalRequestElement(requestId: number, orderRequestElementId: number, keyValueId: number) {
    return this.delete(`${requestId}/RequestElements/${orderRequestElementId}/KeyValues/${keyValueId}`);
  }

  public assignToOrderRequest(orderRequestId: number, supplierCompanyId: number) {
    return this.put<null, OrderRequestModel>(`${orderRequestId}/AssignSupplierCompany`, null, { params: this.setParams({ supplierCompanyId }) });
  }

  getDashboard(filter: OrderRequestDashboardFilterModel): Observable<OrderRequestDashboardModel> {
    return this.post<OrderRequestDashboardFilterModel, OrderRequestDashboardModel>('Dashboard', filter);
  }

  public getLogsByRequestId<T>(requestId: any, body: any = null): Observable<T[]> {
    return this.post<typeof body, T[]>(`${requestId}/Logs/ListAll`, body);
  }

  public getRequestDataToCopy(id: number) {
    return this.get<OrderRequestModel>(`${id}/OrderRequestData`);
  }

  getWallComents(requestId: number): Observable<RequestsWallCommentModel[]> {
    return this.get<RequestsWallCommentModel[]>(`${requestId}/WallComments`);
  }

  createMessage(formData: any, requestId: number, parentId?: number) {
    const body = { ...formData, parentId };
    return this.post<typeof body, number>(`${requestId}/WallComments`, body);
  }

  editMessage(body: any, messageId: number, requestId: number) {
    return this.put<typeof body, void>(`${requestId}/WallComments/${messageId}`, body);
  }

  deleteMessage(messageId: number, requestId: number) {
    return this.delete<void>(`${requestId}/WallComments/${messageId}`);
  }

  confirmProvisionData(id: number, body: OrderRequestProvisionEditModel) {
    return this.put<typeof body, string>(`${id}/ProvisionData`, body, {
      observe: 'response',
      responseType: 'text',
    });
  }
}
