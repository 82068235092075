import { BillingTaxTypes, FloorTypes, StreetTypes } from '../enums';

export interface BillingData {
  firstName: string;
  lastName: string;
  isCompany: boolean;
  email: string;
  nationalIdentityDocument: string;
  bankAccount: string;
  streetType: StreetTypes;
  streetName: string;
  streetNumber: string;
  block: string;
  stairs: string;
  portal: string;
  floorType: FloorTypes;
  gate: string;
  locality: string;
  province: string;
  postalCode: string;
  country: string;
}

export interface BillingDataEditModel {
  id: number;
  billingData: BillingData;
  ivaPercentage: number;
  irpfPercentage: number;
  billingTaxType: BillingTaxTypes;
  automaticInvoicing: boolean;
}

export interface BillingDataModel {
  id: number;
  ivaPercentage: number;
  irpfPercentage: number;
  billingTaxType: BillingTaxTypes;
  billingData: BillingData;
  automaticInvoicing: boolean;
}

export interface FrontBillingDataEditModel {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  isCompany: boolean;
  nationalIdentityDocument: string;
  bankAccount: string;
  streetType: StreetTypes;
  streetName: string;
  streetNumber: string;
  stairs: string;
  portal: string;
  floorType: FloorTypes;
  gate: string;
  block: string;
  locality: string;
  province: string;
  postalCode: string;
  country: string;
  ivaPercentage: number;
  irpfPercentage: number;
  billingTaxType: BillingTaxTypes;
  automaticInvoicing: boolean;
}
