import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { faCamera } from '@fortawesome/free-solid-svg-icons';

import { UnsuscriptionHandler } from '@foxeet/utils/components';
import { takeUntil } from 'rxjs/operators';
import { CoreProfileImageUploaderComponent } from '../../modals/web/user-profile-picture/profile-image-uploader.component';
import { DetailMenuImageService } from '../providers/detail-menu-image.service';

@Component({
  selector: 'ui-detail-menu-image',
  templateUrl: './detail-menu-image.component.html',
  styleUrls: ['./detail-menu-image.component.scss'],
})
export class DetailMenuImageComponent extends UnsuscriptionHandler {
  faCamera = faCamera;

  @Input() userId!: number;
  @Input() imageUrl?: string;
  @Input() uploadUrl!: string;
  @Input() uploadCallback?: string;
  @Input() uploaderEnabled = false;

  @Output() thumbnailFileWebPathChange: EventEmitter<string> = new EventEmitter();

  constructor(protected dialog: MatDialog, private _detailMenuImageService: DetailMenuImageService) {
    super();
  }

  uploadImage(uploadUrl: string, id: number): unknown {
    return (_: any, file: any) => {
      return this._detailMenuImageService.uploadImage(uploadUrl, id, file);
    };
  }

  openModal() {
    this.dialog
      .open(CoreProfileImageUploaderComponent, {
        data: {
          callback: this.uploadCallback ? this.uploadCallback : this.uploadImage(this.uploadUrl, this.userId),
          userId: this.userId,
        },
      })
      .afterClosed()
      .pipe(takeUntil(this._componentDestroyed))
      .subscribe((res) => {
        if (res) {
          this.thumbnailFileWebPathChange.emit(res);
        }
      });
  }
}
