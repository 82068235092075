import {
  BuildingInformationDeckTypes,
  BuildingInformationEnclosureTypes,
  BuildingInformationFoundationTypes,
  BuildingInformationStructureTypes,
  BuildingQualityTypes,
  BuildingUses,
  ConservationStateTypes,
  StreetCategoryTypes,
  SurveillanceTypes,
} from '../enums';

export interface BuildingInformationIndexModel {
  Description: string;
  MainElements_Structure: string;
  MainElements_Deck: string;
  MainElements_Facade: string;
  MainElements_Enclosure: string;
  ConstructionYear: number;
  ReformLastYear: number;
  QualityOfBuilding: BuildingQualityTypes;
  ConservationState: ConservationStateTypes;
  TotalLifts: number;
  TotalFloorsBellowGround: number;
  TotalFloorsAboveGround: number;
  AccessFloor: number;
}
// Nuevos modelos

export interface BuildingInformation {
  mainElements_Structure: BuildingInformationStructureTypes;
  mainElements_StructureObservations: string;
  mainElements_Deck: BuildingInformationDeckTypes;
  mainElements_DeckObservations: string;
  mainElements_Enclosure: BuildingInformationEnclosureTypes;
  mainElements_EnclosureObservations: string;
  mainElements_Foundation: BuildingInformationFoundationTypes;
  mainElements_FoundationObservations: string;
  constructionYear: number;
  lastRehabilitationYear: number;
  constructionYearToUse: number;
  qualityOfBuilding: BuildingQualityTypes;
  conservationState: ConservationStateTypes;
  totalFloorsBellowGround: number;
  totalFloorsAboveGround: number;
  buildingUse: BuildingUses;
  maxUsefulLife: number;
  usefulLife: number;
  remainingUsefulLife: number;
  numberOfBuildingAccesses: number;
  streetCategory: StreetCategoryTypes;
  hasSwimmingPool: boolean;
  hasSportsZone: boolean;
  hasGreenArea: boolean;
  surveillance: SurveillanceTypes;
  observations: string;
  allFields: string[];
  totalCompletedPercentage: number;
  totalRequiredCompletedPercentage: number;
  hasTennisOrPaddle: boolean;
  hasCommonFacilities: boolean;
  commonFacilitiesSurface: number;
  elevatorsCount: number;
}
