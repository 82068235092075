export interface LicenseInvoiceIndexModel {
  id: number;
  supplierCompanyId: number;
  invoiceNumber: string;
  invoiceDateTime: Date;
  value: number;
  addedUserFullName: string;
  taxTotalValue: number;
  totalWithTaxes: number;
  totalWithoutTaxes: number;
  fileWebPath: string;
}
