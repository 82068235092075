import { FileTypes } from '../enums';

export interface AppraisalAssetPhotoItemModel {
  name: string;
  description: string;
  file: Blob;
  filename: string;
  isPrintable: boolean;
}

export interface AppraisalAssetMultiplePhotosModel {
  assetId: number;
  fileType: FileTypes;
  items: AppraisalAssetPhotoItemModel[];
}
