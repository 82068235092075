export interface AssetCheckInformation {
  // AssetCheckModel
  groupKey: string;
  translateGroupKey: string;
  key: string;
  value: boolean;
  isVisible: boolean;
  translateKey: string;
}

export interface AssetCheckDataModel {
  // antiguo AssetCheckData
  hasPhysicalAndRegisteredIdenficication: boolean;
  hasVisibleEasements: boolean;
  hasConservationState: boolean;
  hasOccupationStateUseAndExploitation: boolean;
  hasPublicProtectionRegime: boolean;
  hasArquitectonicProtectionRegime: boolean;
  hasCurrentPlanAdequation: boolean;
  hasCorrespondanceWithRegisteredProperty: boolean;
  hasCadastralProperty: boolean;
  hasExpropiation: boolean;
  hasSimpleNoteUpdated: boolean;
  hasSimpleNoteOutdated: boolean;
  hasRegisteredPropertyDeed: boolean;
  hasUnregisteredPropertyDeed: boolean;
  hasRegisteredCertificationUpdated: boolean;
  hasProperyRegisterBookCopy: boolean;
  hasCadastralRecord: boolean;
  hasCadastralCertification: boolean;
  hasCadastralGoodsCertificate: boolean;
  hasIBIBill: boolean;
  hasUrbanisticNormative: boolean;
  hasUrbanisticIdentification: boolean;
  hasTownHallCertificate: boolean;
  hasSituation: boolean;
  hasAssetSketch: boolean;
  hasUrbanismPlan: boolean;
  hasPhotos: boolean;
  hasProvisionalCalificationCertificate: boolean;
  hasDefinitiveCalificationCertificate: boolean;
  hasEnoughLegalDocumentationToVML: boolean;
  hasDescalificationDocument: boolean;
  hasLeasingContract: boolean;
  hasLastRentPaymentBill: boolean;
  hasDocumentOfLeasingInexistenceAndEvictionCommitment: boolean;
  hasVisaProject: boolean;
  hasNoVisaProject: boolean;
  hasBuildingLicensce: boolean;
  hasLastBuildingCertificateFromProjectManagement: boolean;
  hasBuildExecutionContract: boolean;
  hasContractBudget: boolean;
  has10YearDamageInsurance: boolean;
  hasFinalBuildingCertificate: boolean;
  hasDeclarationOfNewBuild: boolean;
  hasInformationOfAverageAmountOfSector: boolean;
  hasExploitationCounts: boolean;
  hasIrrigationRights: boolean;
  hasEquivalentDocumentationToIrrigationRights: boolean;
  hasOwnerCommunityStatutes: boolean;
  hasCommunityAdministratorCertificate: boolean;
  hasActivityLicensce: boolean;
  hasLeaseData: boolean;
  hasEdificationTemsNotExpired: boolean;
  hasInexistentProceduresOfNonComplianceOfObligations: boolean;
  hasRightsofUrbanExploitation: boolean;
  hasWorkProjectEndorsed: boolean;
  hasBuildingLicense: boolean;
  hasCertificationWork: boolean;
  hasWorkExecutionContract: boolean;
}
