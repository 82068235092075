import { InternationalizationTypes } from '@foxeet/domain';
import { DecimalFormatterPipe, EnumToStringPipe, TimeLeftPipe, ToDatePipeByLocale, ToPendingState } from '@foxeet/utils-modules';
import { StatusBadgesComponent } from '../../../../components/generic-tables/cell-components/status-badges/status-badges.component';
import { EntryComponentContentTypes, TableCellType, TableColumn } from '../../../../components/generic-tables/domain/table.model';

const columnCheckBoxAppraisalList: TableColumn = {
  columnId: 'checkBox',
  name: '',
  cellType: TableCellType.CHECKBOX,
};

const columnStatusAppraisalList: TableColumn = {
  columnId: 'status',
  name: '',
  entryComponent: StatusBadgesComponent,
  cellType: TableCellType.ENTRY_COMPONENT,
  entryComponentContentTypes: EntryComponentContentTypes.APPRAISAL,
};

const columnExecutionToolIdAppraisalList: TableColumn = {
  columnId: 'executionToolId',
  name: 'APPRAISALS_internalReference',
  sortable: true,
};

const columnExternalRecordIdAppraisalList: TableColumn = {
  columnId: 'externalRecordId',
  name: 'APPRAISALS_externalReference',
  sortable: true,
};

const columnRecordIdAppraisalList: TableColumn = {
  columnId: 'recordId',
  name: 'APPRAISALS_fileNumber',
  cellType: TableCellType.LINK,
};

const columnAddressAppraisalList: TableColumn = {
  columnId: 'addressGeneratedName',
  name: 'APPRAISALS_address',
};

const columnLocalityAppraisalList: TableColumn = {
  columnId: 'locality',
  name: 'APPRAISALS_locality',
  sortable: true,
};

const columnProvinceAppraisalList: TableColumn = {
  columnId: 'province',
  name: 'APPRAISALS_province',
  sortable: true,
};

const columnAddedDateTimeAppraisalList: TableColumn = {
  columnId: 'addedDateTime',
  name: 'APPRAISALS_creationDate',
  pipe: ToDatePipeByLocale,
  cellType: TableCellType.PIPE,
  sortable: true,
};

const columnFinishedDateTimeAppraisalList: TableColumn = {
  columnId: 'finishedDateTime',
  name: 'APPRAISALS_finishedDate',
  pipe: ToDatePipeByLocale,
  cellType: TableCellType.PIPE,
  sortable: true,
};

const columnVisitScheduleDeadlineDateTimeAppraisalList: TableColumn = {
  columnId: 'visitScheduleDeadlineDateTime',
  name: 'APPRAISALS_dateVisitScheduleDeadline',
  pipe: ToDatePipeByLocale,
  cellType: TableCellType.PIPE,
};

const columnVisitScheduleDateTimeAppraisalList: TableColumn = {
  columnId: 'visitScheduleDateTime',
  name: 'APPRAISALS_visitScheduled',
  pipe: ToDatePipeByLocale,
  cellType: TableCellType.PIPE,
};

const columnVisitStartDateTimeAppraisalList: TableColumn = {
  columnId: 'visitStartDateTime',
  name: 'APPRAISALS_visitStarted',
  pipe: ToDatePipeByLocale,
  cellType: TableCellType.PIPE,
};

const columnVisitFinishDateTimeAppraisalList: TableColumn = {
  columnId: 'visitFinishDateTime',
  name: 'APPRAISALS_visitFinished',
  pipe: ToDatePipeByLocale,
  cellType: TableCellType.PIPE,
};

const columnAppraiserAllDataSentDeadlineDateTimeAppraisalList: TableColumn = {
  columnId: 'appraiserAllDataSentDeadlineDateTime',
  name: 'APPRAISALS_dateSendDataDeadline',
  pipe: ToDatePipeByLocale,
  cellType: TableCellType.PIPE,
  sortable: true,
};

const columnAppraiserAllDataSentDateTimeAppraisalList: TableColumn = {
  columnId: 'appraiserAllDataSentDatetime',
  name: 'APPRAISALS_appraiserAllDataSentDate',
  pipe: ToDatePipeByLocale,
  cellType: TableCellType.PIPE,
  sortable: true,
};

const columnValidationStartDateTimeAppraisalList: TableColumn = {
  columnId: 'validationStartDatetime',
  name: 'APPRAISALS_validationStarted',
  pipe: ToDatePipeByLocale,
  cellType: TableCellType.PIPE,
};

const columnValidationFinishDateTimeAppraisalList: TableColumn = {
  columnId: 'validationFinishDatetime',
  name: 'APPRAISALS_validationFinished',
  pipe: ToDatePipeByLocale,
  cellType: TableCellType.PIPE,
};

const columnCancelledDateTimeAppraisalList: TableColumn = {
  columnId: 'cancelledDateTime',
  name: 'APPRAISALS_cancelledDate',
  pipe: ToDatePipeByLocale,
  cellType: TableCellType.PIPE,
  sortable: true,
};

const columnCustomerDeadlineDateTimeAppraisalList: TableColumn = {
  columnId: 'customerDeadlineDateTime',
  name: 'APPRAISALS_deliverDeadlineToCustomer',
  pipe: ToDatePipeByLocale,
  cellType: TableCellType.PIPE,
  sortable: true,
};

const columnCurrentStepValueAppraisalList: TableColumn = {
  columnId: 'currentStepValue',
  name: 'APPRAISALS_status',
  pipe: EnumToStringPipe,
  keysPipe: ['AppraisalStatus'],
  cellType: TableCellType.PIPE,
  sortable: true,
};
const columnSupplierCompanyUserAppraisalList: TableColumn = {
  columnId: 'supplierCompanyUserFullName',
  name: 'REQUESTS_resolver',
};

const columnProcessManagerUserAppraisalList: TableColumn = {
  columnId: 'processManagerUserFullName',
  name: 'APPRAISALS_processManager',
};

const columnTechnicalManagerUserAppraisalList: TableColumn = {
  columnId: 'technicalManagerUserFullName',
  name: 'APPRAISALS_techManager',
};

const columnExternalWorkerUserAppraisalList: TableColumn = {
  columnId: 'appraiserUserFullName',
  name: 'APPRAISALS_LIST_professional',
};

const columnCustomerAppraisalList: TableColumn = {
  columnId: 'customerFullName',
  name: 'APPRAISALS_customer',
};

const columnPointerTermAppraisalList: TableColumn = {
  columnId: 'deadlineGradeRange',
  name: 'APPRAISALS_pointerTerm',
  cellType: TableCellType.PIPE,
  pipe: DecimalFormatterPipe,
  sortable: true,
};

const columnExecutionNoteAppraisalList: TableColumn = {
  columnId: 'executionGradeRange',
  name: 'APPRAISALS_executionNote',
  cellType: TableCellType.PIPE,
  pipe: DecimalFormatterPipe,
  sortable: true,
};

const columnUserAppraisalList: TableColumn = {
  columnId: 'appraiserUserFullName',
  name: 'APPRAISALS_appraiserUserFullName',
};

const columnValorationTotalValueAppraisalList: TableColumn = {
  columnId: 'valorationTotalValue',
  name: 'APPRAISALS_valorationTotalValue',
  cellType: TableCellType.PIPE,
  pipe: DecimalFormatterPipe,
  keysPipe: [InternationalizationTypes.Currency],
};

const columnValidationFinishDatetimeAppraisalList: TableColumn = {
  columnId: 'validationFinishDatetime',
  name: 'APPRAISALS_validationFinishDatetime',
  pipe: ToDatePipeByLocale,
  cellType: TableCellType.PIPE,
};

const columnVisitTypeAppraisalList: TableColumn = {
  columnId: 'visitType',
  name: 'APPRAISALS_visitType',
};

const columnOrderTypeAppraisalList: TableColumn = {
  columnId: 'orderType',
  name: 'APPRAISALS_orderType',
  cellType: TableCellType.PIPE,
  pipe: EnumToStringPipe,
  keysPipe: ['AppraisalOrderTypes'],
};

const columnVinculationTypeAppraisalList: TableColumn = {
  columnId: 'vinculation',
  name: 'APPRAISALS_vinculation',
  cellType: TableCellType.PIPE,
  pipe: EnumToStringPipe,
  keysPipe: ['AppraisalVinculations'],
};

const columnValorationValueAppraisalList: TableColumn = {
  columnId: 'valorationValue',
  name: 'APPRAISALS_valorationValue',
  cellType: TableCellType.PIPE,
  pipe: DecimalFormatterPipe,
  keysPipe: [InternationalizationTypes.Currency],
};

const columnIsAssetChangedAppraisalList: TableColumn = {
  columnId: 'isAssetChanged',
  name: 'APPRAISALS_coincidence',
  cellType: TableCellType.BOOLEAN,
};

const columAssignmentBagAppraisalList: TableColumn = {
  columnId: 'isInExchange',
  name: 'APPRAISALS_assignmentBag',
  cellType: TableCellType.PIPE,
  pipe: ToPendingState,
};

const columBagTimeLeftAppraisalList: TableColumn = {
  columnId: 'exchangeFinishDateTime',
  name: 'APPRAISALS_assignmentBagTimeLeft',
  cellType: TableCellType.PIPE,
  pipe: TimeLeftPipe,
};

const columnIsInvoicedInOrderRequest: TableColumn = {
  columnId: 'isInvoicedInOrderRequest',
  name: 'APPRAISALS_IsInvoicedInOrderRequest',
  cellType: TableCellType.BOOLEAN,
};

export {
  columnIsInvoicedInOrderRequest,
  columnExecutionToolIdAppraisalList,
  columnExternalRecordIdAppraisalList,
  columnAddressAppraisalList,
  columnLocalityAppraisalList,
  columnProvinceAppraisalList,
  columnStatusAppraisalList,
  columnAddedDateTimeAppraisalList,
  columnCustomerAppraisalList,
  columnCurrentStepValueAppraisalList,
  columnSupplierCompanyUserAppraisalList,
  columnProcessManagerUserAppraisalList,
  columnTechnicalManagerUserAppraisalList,
  columnCheckBoxAppraisalList,
  columAssignmentBagAppraisalList,
  columBagTimeLeftAppraisalList,
  columnVisitScheduleDeadlineDateTimeAppraisalList,
  columnExternalWorkerUserAppraisalList,
  columnVisitScheduleDateTimeAppraisalList,
  columnVisitStartDateTimeAppraisalList,
  columnVisitFinishDateTimeAppraisalList,
  columnAppraiserAllDataSentDeadlineDateTimeAppraisalList,
  columnAppraiserAllDataSentDateTimeAppraisalList,
  columnValidationStartDateTimeAppraisalList,
  columnCustomerDeadlineDateTimeAppraisalList,
  columnValidationFinishDateTimeAppraisalList,
  columnFinishedDateTimeAppraisalList,
  columnCancelledDateTimeAppraisalList,
  columnRecordIdAppraisalList,
  columnOrderTypeAppraisalList,
  columnVisitTypeAppraisalList,
  columnValorationValueAppraisalList,
  columnIsAssetChangedAppraisalList,
  columnVinculationTypeAppraisalList,
  columnUserAppraisalList,
  columnValorationTotalValueAppraisalList,
  columnValidationFinishDatetimeAppraisalList,
  columnPointerTermAppraisalList,
  columnExecutionNoteAppraisalList,
};
