import { AssetUses, ReportActions, ValorationMethods, WorkerRoles } from '../enums';

export interface CommonAssetDataIndexModel {
  assetRequiredCompletedPercent: number;
  assetTotalCompletedPercent: number;
  id: number;
  isAllAssetCompleted: boolean;
  isAllRequiredAssetCompleted: boolean;
  visitForm_EnvironmentAndBuildingInformationRequiredCompletedPercent: number;
  visitForm_IsAllCompleted: boolean;
  visitForm_IsAllRequiredCompleted: boolean;
  isWorkPlanStateRecalculationNeeded: boolean;
}

export interface WorkflowItemReportAssetCheckIfReplaceCompModel {
  ComparablesWillBeReplaced: boolean;
  Message: string;
}

export interface ValorationChecksModel {
  comparablesWillBeReplaced: boolean;
  message: string;
  showWorkplanStateResidualValorationModal: boolean;
  hasErrors: boolean;
  assets: string[];
}

///////////////////////////////
// Nuevos modelos

export interface AppraisalReportFilter {
  summary: string;
  activatedFiltersCount: number;
  orderFieldName: string;
  orderFieldAsc: boolean;
  id: number;
  ids: number[];
  workflowItemId: number;
  version: number;
  addedMaxDateTime: string;
  addedMinDateTime: string;
}

export interface AppraisalReportIndexModel {
  id: number;
  addedDateTime: string;
  addedUserFullName: string;
  addedUserId: number;
  assignedUserFullName: number;
  assignedUserId: number;
  finishedDateTime: string;
  currentUserActionsPermisions: ReportActions;
  canEditStructure: boolean;
  isFinished: boolean;
  isValidated: boolean;
  lastUpdatedDateTime: string;
  lastUpdatedUserFullName: string;
  lastUpdatedUserId: number;
  observations: string;
  valorationTotalValue: number;
  version: number;
  workflowItemId: number;
  assignedUserWorkerRoleValue: WorkerRoles;
  // Validations
  errorCount?: number;
  warningCount?: number;
  infoCount?: number;
}

export interface WorkflowItemReportInitialDetails {
  reportId: number;
  workflowItemId: number;
  version: number;
  currentUserActionsPermisions: ReportActions;
  cultureCode: string;
  canEditStructure: boolean;
  cultureCodeCurrency: string;
  cultureCodeLanguage: string;
}

export interface ReportValorationSummary {
  assets: AppraisalReportAssetValorationSummary[];
  total: number;
}

export interface AppraisalReportAssetValorationSummary {
  element: string;
  registeredPropertyNumber: string;
  cadastralReference: string;
  adoptedSurface: number;
  totalValue: number;
  unitaryValue: number;
  adoptedMethod: ValorationMethods;
  children: AppraisalReportAssetValorationSummary[];
  subtotal: number;
  use: AssetUses;
}
