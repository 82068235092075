import { FloorTypes, SimpleNoteErrorTypes, StreetTypes } from '../enums';
import { UserIndexModel } from './user.model';
import { IWorkflowStepModel } from './workflow-step.model';

export interface RegistryRequestIndexModel {
  id: number;
  customerId: number;
  requesterCompanyId: number;
  supplierCompanyId: number;
  currentStepValue: number;
  addedDateTime: Date;
  addedUserId: number;
  isFinished: boolean;
  isCancelled: boolean;
  cancelledUserId: number;
  ownerFirstName: string;
  ownerLastName: string;
  ownerNationalIdentityDocument: string;
  prescriptorRecordId: string;
  cadastralReference: string;
  idufir: string;
}

export interface RegistryRequestModel {
  id: number;
  customerId: number;
  requesterCompanyId: number;
  supplierCompanyId: number;
  currentStepValue: number;
  isFinished: boolean;
  finishedDateTime: string;
  isCancelled: boolean;
  cancelledUserId: number;
  addedDateTime: string;
  addedUserId: number;
  steps: IWorkflowStepModel[];
  salePriceOfRequesterCompany: number;
  salePriceOfSupplierCompany: number;
  otherExpensesOfSupplierCompany: number;
  feeOfTechnical: number;
  otherExpensesOfTechnical: number;
  cancelledDescription: string;
  cancelledDateTime: string;
  cancelledUser: UserIndexModel;
  invoiceMonth: number;
  invoiceYear: number;
  fileName: string;
  fileWebPath: string;
  ownerFirstName: string;
  ownerLastName: string;
  ownerNationalIdentityDocument: string;
  streetType: StreetTypes;
  streetName: string;
  streetNumber: string;
  block: string;
  stairs: string;
  portal: string;
  floorType: FloorTypes;
  gate: string;
  locality: string;
  province: string;
  postalCode: string;
  country: string;
  canCancel: boolean;
  canCreateIncident: boolean;
  canEdit: boolean;
  canSentToRegister: boolean;
  canSetSuccessReceivedFromRegister: boolean;
  canSetFailedReceivedFromRegister: boolean;
  canSetDeliveredToClient: boolean;
  canAddFileRegister: boolean;
  canRemoveFileRegister: boolean;
  cadastralReference: string;
  propertyRegisterName: string;
  registeredPropertyNumber: string;
  volume: string;
  book: string;
  paper: string;
  section: string;
  inscription: string;
  idufir: string;
  prescriptorRecordId: string;
  propertyRegisterGeoSmartaId: string;
  //  New added
  errorMessage: string;
  errorType: SimpleNoteErrorTypes;
  hasError: boolean;
}

export interface RegistryRequestEditModel {
  id: number;
  customerId: number;
  requesterCompanyId: number;
  supplierCompanyId: number;
  prescriptorRecordId: string;
  cadastralReference: string;
  realEstateNumber: string;
  registerName: string;
  registerNumber: string;
  book: string;
  section: string;
  page: string;
  inscription: string;
  idufir: string;
  ownerFirstName: string;
  ownerLastName: string;
  ownerNationalIdentityDocument: string;
  streetType: StreetTypes[];
  streetName: string;
  streetNumber: string;
  block: string;
  stairs: string;
  portal: string;
  floorType: FloorTypes;
  gate: string;
  locality: string;
  province: string;
  postalCode: string;
  country: string;
}
