import { OrNull } from './global.interfaces';

/**
 * @todo Se movera cuando se desplaze el componente tabla fuera de core
 */
export interface FilterModel {
  size?: number;
  page?: number;
  activeFilterBarTab?: OrNull<number>;
  data?: { [key: string]: any };
}
