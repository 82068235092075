// TODO: check with back
export interface OrderRequestPromotionalCodeModel {
  lastUpdatedDateTime: string;
  lastUpdatedUserId: number;
  id: number;
  isActive: boolean;
  promotionalCode: string;
  discountPercent: number;
  isActiveFromDateTime: string;
  isActiveUntilDateTime: string;
  addedDateTime: string;
  addedUserId: number;
}

export interface OrderRequestPromotionalCodeEditModel {
  id: number;
  isActive: boolean;
  promotionalCode: string;
  discountPercent: number;
  isActiveFromDateTime: string;
  isActiveUntilDateTime: string;
}

export interface OrderRequestPromotionalCodeIndexModel {
  id: number;
  isActive: boolean;
  promotionalCode: string;
  discountPercent: number;
  isActiveFromDateTime: string;
  isActiveUntilDateTime: string;
  addedDateTime: string;
  addedUserId: number;
  addedUserFullName: string;
  // addedUser: UserIndexModel;
}
