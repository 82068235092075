<div class="d-flex align-items-center">
  <ng-container *ngIf="header.columnId === 'executionGrade'">
    <p>{{ data.executionGrade }}</p>
    <div *ngIf="data.executionGrade" class="stars-container">
      <ui-stars-rating [id]="id" [score]="data.executionGrade"></ui-stars-rating>
    </div>
  </ng-container>
  <ng-container *ngIf="header.columnId === 'deadlineGrade'">
    <p>{{ data.deadlineGrade }}</p>
    <div *ngIf="data.deadlineGrade" class="stars-container">
      <ui-stars-rating [id]="id" [score]="data.deadlineGrade"></ui-stars-rating>
    </div>
  </ng-container>
</div>
