import { AppraisalOrderTypes, AppraisalPurposes, LicenseeTypes, WorkerRoles } from '../enums/backend.enums';
import { WorkflowItemStepDataModel } from './dashboard.model';

export interface OrderRequestDashboardFilterModel {
  orderType?: AppraisalOrderTypes;
  orderTypes?: number[];
  purpose?: AppraisalPurposes;
  purposes?: number[];
  addedMaxDateTime?: string;
  addedMinDateTime?: string;
  workflowItemLicenseeTypeAndLicenseeIds: Map<LicenseeTypes, number[]>;
  workerRoleAndUserIds: Map<WorkerRoles, number[]>;
  userIdAndWorkerRole?: number;
  licenseeIdAndWorkflowItemLicenseeType?: number;
}

export interface OrderRequestDashboardModel {
  workflowItemCount: number;
  workflowItemStepData: WorkflowItemStepDataModel[];
}
