import { AssetSurfaceIds, ValorationLegalMaximumOptions } from '../enums';
import { FoxeetKeyValueModel } from './extra-information.model';
export interface ValorationLegalMaximumMethodModel {
  id: number;
  assetId: number;
  clusterId: number;
  buildingElementId: number;
  totalAdoptedSurface: number;
  assetSurfaceId: AssetSurfaceIds;
  depreciationFunctionalAdoptedTotalValue: number;
  depreciationFunctionalAdoptedUnitValue: number;
  groundRepercussionAdoptedUnitValue: number;
  groundRepercussionAdoptedTotalValue: number;
  observations: string;
  buildeable: number;
  numberOfElementsToBuild: number;
  isQualificationCardSurface: boolean;
  valorationLegalMaximumOption: ValorationLegalMaximumOptions;
  valorationLegalMaximumCustomValue: number;
  valorationLegalMaximumValue: number;
  valorationLegalMaximumUnitValue: number;
  valorationLegalMaximumMainAssetValue: number;
  valorationLegalMaximumMainAssetUnitValue: number;
  limitationGroundValuePercent: number;
  valorationLegalMaximumLimitationGroundValue: number;
  valorationLegalMaximumLimitationGroundTotalValue: number;
  staticAbriddedResidual_Cost_TotalAdoptedSurface: number;
  staticAbriddedResidual_Cost_AssetSurfaceId: AssetSurfaceIds;
  staticAbriddedResidual_Cost_ConstructionCostsUnitPrice: number;
  staticAbriddedResidual_Cost_ConstructionExpensesUnitPrice: number;
  staticAbriddedResidual_Cost_FinancialExpensesPercentage: number;
  staticAbriddedResidual_Cost_MarketingExpensesPercentage: number;
  staticAbriddedResidual_Cost_DeveloperProfitPercentage: number;
  staticAbriddedResidual_Cost_KCoeficient: number;
  staticAbriddedResidual_MarketValueUnitPrice: number;
  staticAbriddedResidual_UnitValue: number;
  staticAbriddedResidual_TotalValue: number;
  isFutureValoration: boolean;
  isMigrationValoration: boolean;
  keyValues: FoxeetKeyValueModel[];
}

export interface ValorationLegalMaximumMethodParentAssetData {
  parentId: number;
  isParent: boolean;
  parentHasLegalMaximumMethod: boolean;
  parentValorationLegalMaximumValue: number;
  parentValorationLegalMaximumUnitValue: number;
}
