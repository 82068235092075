import { AssetSurfaceIds, BuildingUses, ValorationMethods } from '../enums';
import { FoxeetKeyValueModel } from './extra-information.model';
import { DiscountCashflow } from './valoration-discount-method.model';

export interface ValorationContractCashflowMethodModel {
  id: number;
  assetId: number;
  clusterId: number;
  buildingElementId: number;
  totalAdoptedSurface: number;
  assetSurfaceId: AssetSurfaceIds;
  depreciationFunctionalAdoptedTotalValue: number;
  depreciationFunctionalAdoptedUnitValue: number;
  groundRepercussionAdoptedUnitValue: number;
  groundRepercussionAdoptedTotalValue: number;
  observations: string;
  buildeable: number;
  numberOfElementsToBuild: number;
  selectedValorationValue: number;
  selectedValorationMethod: ValorationMethods;
  selectedValorationId: number;
  buildingConstructionYearToUse: number;
  buildingUse: BuildingUses;
  valorationDatetime: string;
  contractStartDatetime: string;
  contractFinishDatetime: string;
  firstChargeDatetime: string;
  costMethod_ConstructionUnitCost: number;
  costMethod_NecessaryConstructionExpensesUnitPrice: number;
  monthlyGrossIncome: number;
  monthlyExpensesPercent: number;
  monthlyNetIncome: number;
  updateRatePercent: number;
  monthlyEquivalentUpdateRatePercentage: number;
  monthlyEquivalentUpdateRatePercent: number;
  capitalGainPercent: number;
  reversionValue: number;
  reversionCustomValue: number;
  updatedIncomeTotalValue: number;
  updatedReversionValue: number;
  totalValue: number;
  monthlyDiscountCashflows: DiscountCashflow[];
  yearlyDiscountCashflows: DiscountCashflow[];
  isFutureValoration: boolean;
  regardingNetIncomeObservations: string;
  isMigrationValoration: boolean;
  keyValues: FoxeetKeyValueModel[];
}
