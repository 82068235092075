import {
  CeilingTypes,
  ExternalWoodworksTypes,
  HeatingTypes,
  HotWaterTypes,
  InternalWoodWork,
  LicenseTypes,
  PavementTypes,
  PolivalentUses,
  PropertySizes,
  StayTypes,
  WallTypes,
} from '../enums';

// TODO: move to a new file
export interface AssetStayInformationModel {
  // id: number; // TODO: esto pasa al padre AssetTabModel
  stays: AssetStayModel[];
  distribution: string;
}

export interface AssetStayAndFacilityEditModel {
  stays: AssetStayModel[];
  hasAirConditioning: boolean;
  hasGas: boolean;
  hasPlumbingAndSanitaryEquipment: boolean;
  hasIntercom: boolean;
  hasPhone: boolean;
  hasSanitation: boolean;
  externalWoodWork: ExternalWoodworksTypes;
  facilityObservations: string;
  distribution: string;
  hasEquippedKitchen: boolean;
  hasBuiltInWardrobes: boolean;
  hasAlarmSystem: boolean;
  hasClosedSecurityCircuit: boolean;
  hotWaterType: HotWaterTypes;
  heatingType: HeatingTypes;
  propertySize: PropertySizes;
  license: LicenseTypes;
  polivalentUse: PolivalentUses;
  facade: number;
  depth: number;
  clearanceHeight: number;
}

export interface AssetStayModel {
  id: number;
  stayType: StayTypes;
  description: string;
  totalSurface: number;
  pavementType: PavementTypes;
  wallType: WallTypes;
  ceilingType: CeilingTypes;
  internalWoodWork: InternalWoodWork;
}
