export interface CatastroAssetLocalizationModel {
  ineCodProv: number;
  ineCodLocality: number;
  ineCodVia: number;
  number: string;
  latitude: number;
  longitude: number;
  plotCode: number;
  polygon: number;
}
