import { LicenseeReportCustomizeTypes } from '../enums';

export interface LicenseeReportCustomizeEditModel {
  includeLogo: boolean;
  includeSignature: boolean;
  footerLine1: string;
  footerLine2: string;
  footerLine3: string;
  includeTextInCertificate: boolean;
  certificateText: string;
  certificateTextIsAppraisalCompany: boolean;
}

export interface LicenseeReportCustomizeFileModel {
  filePath: string;
  addedDateTime: string;
  fileType: LicenseeReportCustomizeTypes;
}
