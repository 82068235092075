import {
  AppraisalOrderTypes,
  AppraisalPurposes,
  AppraisalVinculations,
  AppraisalVisitTypes,
  AssetStates,
  AssetSubTypologies,
  AssetTypologies,
  AssetUses,
  BuildingStates,
  EntityTypes,
  FeeOptions,
  FloorTypes,
  LicenseeTypes,
  OccupationStates,
  PaymentOptions,
  PaymentStates,
  PriceRanges,
  PublicProtections,
  ReportTypes,
  StreetTypes,
  VinculationTypes,
  WorkTypes,
  WorkerRoles,
} from '../enums';
import { WorkflowItemLicenseeIndexModel, WorkflowItemWorkerIndexModel } from './appraisal.model';
import { VisitProposalDataModel } from './order-visit.model';
import { ApiPropertyRegister } from './register.models';
import { IWorkflowStepModel } from './workflow-step.model';

// TODO: review if needed
export interface DbGeographyWellKnownValue {
  CoordinateSystemId: number;
  WellKnownText: string;
  WellKnownBinary: number[];
  // WellKnownBinary	: Collection of byte	;
}

// TODO: review if needed
export interface DbGeography {
  Geography: DbGeographyWellKnownValue;
}

///////////////////////////////////////////////////////
// Nuevos modelos

export interface OrderRequestFilterModel {
  id: number;
  ids: number[];
  currentWorkflowStep: number;
  currentWorkflowSteps: number[];
  excludedCurrentWorkflowSteps: number[];
  batchId: number;
  isFinished: boolean;
  finishedMaxDateTime: string;
  finishedMinDateTime: string;
  addedMaxDateTime: string;
  addedMinDateTime: string;
  isCancelled: boolean;
  cancelledMaxDateTime: string;
  cancelledMinDateTime: string;
  withIncidents: boolean;
  withIncidentsNotResolved: boolean;
  withIncidentsMaxDateTime: string;
  withIncidentsMinDateTime: string;
  withClaims: boolean;
  withClaimsNotResolved: boolean;
  withClaimsMaxDateTime: string;
  withClaimsMinDateTime: string;
  excludedId: number;
  excludedIds: number[];
  summary: string;
  activatedFiltersCount: number;
  orderFieldName: string;
  orderFieldAsc: boolean;
  possibleOrderFieldNames: string[];
  withRevisions: boolean;
  withRevisionsNotResolved: boolean;
  withRevisionsMaxDateTime: string;
  withRevisionsMinDateTime: string;
  rootId: number;
  rootIds: number[];
  parentId: number;
  parentIds: number[];
  licenseeId: number;
  licenseeIds: number[];
  licenseeIdOrs: number[];
  workerRoles: number[];
  externalRecordId: string;
  externalRecordIds: string[];
  containsExternalRecordId: string;
  containsOwnerFullName: string;
  containsOwnerNationalIdentityDocument: string;
  visitType: AppraisalVisitTypes;
  purposes: AppraisalPurposes[];
  opportunityId: string;
  workflowItemLicenseeTypeAndLicenseeIds: Map<LicenseeTypes, number[]>;
  workerRoleAndUserIds: Map<WorkerRoles, number[]>;
  paymentId: number;
  paymentIds: number[];
  paymentRedsysPaymentId: string;
  paymentRedsysPaymentIdContains: string;
  paymentState: PaymentStates;
  paymentAddedMaxDateTime: any;
  paymentAddedMinDateTime: any;
  fromPartner: boolean;
}

export interface OrderRequestIndexModel {
  id: number;
  parentId: number;
  rootId: number;
  currentStepValue: number;
  addedDateTime: string;
  addedUserId: number;
  isFinished: boolean;
  isCancelled: boolean;
  cancelledUserId: number;
  workflowItemLicensees: WorkflowItemLicenseeIndexModel[];
  workers: WorkflowItemWorkerIndexModel[];
  hasAppraisals: boolean;
  appraisalVinculationType: VinculationTypes;
  appraisalExternalVinculationId: string;
  fromPartner: boolean;
  withOpportunityId: boolean;
  isCustomerPendingConfirmEntityType: boolean;
}

export interface RequestsWallCommentModel {
  addedDateTime: string;
  addedUserFullName: string;
  addedUserProfileImageFileName: string;
  addedUserId: number;
  comment: string;
  id: number;
  lastUpdatedDateTime: string;
  lastUpdatedUserId: number;
  parentId?: number;
  replies: RequestsWallCommentModel[];
  workflowItemId: number;
}

export interface OrderRequestModel {
  id: number;
  parentId: number;
  rootId: number;
  currentStepValue: number;
  addedDateTime: string;
  addedUserId: number;
  isFinished: boolean;
  isCancelled: boolean;
  cancelledUserId: number;
  workflowItemLicensees: WorkflowItemLicenseeIndexModel[];
  workers: WorkflowItemWorkerIndexModel[];
  hasAppraisals: boolean;
  cancelledDescription: string;
  cancelledDateTime: string;
  cancelledUserFirstName: string;
  cancelledUserLastName: string;
  canWriteWallComments: boolean;
  externalRecordId: string;
  externalConnectionContractId: number;
  ownerIsEnterprise: boolean;
  ownerFirstName: string;
  ownerFirstNameSearchPattern: string;
  ownerLastName: string;
  ownerLastNameSearchPattern: string;
  ownerNationalIdentityDocument: string;
  ownerPhoneNumber: string;
  ownerEmail: string;
  ownerEntityType: EntityTypes;
  orderType: AppraisalOrderTypes;
  purpose: AppraisalPurposes;
  visitType: AppraisalVisitTypes;
  withEco8052003: boolean;
  invoiceIsEnterprise: boolean;
  invoiceFirstName: string;
  invoiceLastName: string;
  invoiceNationalIdentityDocument: string;
  invoiceEmail: string;
  invoiceAddress: string;
  invoicePostalCode: string;
  invoiceLocality: string;
  invoiceProvince: string;
  appraiserPartnerWillVisit: boolean;
  appraiserPartnerWillDoReport: boolean;
  observations: string;
  opportunityId: string;
  commercialFeePercentage: number;
  technicalFeePercentage: number;
  visitName: string;
  visitPhone: string;
  visitEmail: string;
  visitObservations: string;
  paymentOption: PaymentOptions;
  steps: IWorkflowStepModel[];
  requestElements: OrderRequestElementModel[];
  visitProposals: VisitProposalDataModel[];
  canBeLaunchedInExchange: boolean;
  finishedDateTime: string;
  canCancel: boolean;
  canResendPaymentEmail: boolean;
  feeOption: FeeOptions;
  provision_SalePrice: number;
  provision_AppraiserFee: number;
  provision_CommercialFee: number;
  provision_OtherExpenses: number;
  provision_OtherExpensesRappel: number;
  provision_OtherExpensesSimpleNote: number;
  isAutomaticInvoice: boolean;
  appraisalVinculation: AppraisalVinculations;
  appraisalVinculationParentId: number;
  reportType: ReportTypes;
  assignedRequestDateTime: string;
  acceptedQuoteDateTime: string;
  appraisalsCreatedDateTime: string;
  // TODO: Lo dejamos?
  canAssignResolverUser: boolean;
  appraisalVinculationType: VinculationTypes;
  appraisalExternalVinculationId: string;
  workType: WorkTypes;
  withOpportunityId: boolean;
  isCustomerPendingConfirmEntityType: boolean;
}

// TODO: Review optionals.
export interface OrderRequestElementModel {
  // ADDED PARENT ID TEMPORALLY
  parentId?: number;
  //
  id?: number;
  orderRequestId?: number;
  occupationState?: OccupationStates;
  buildingState?: BuildingStates;
  publicProtection?: PublicProtections;
  simpleNoteRequest?: boolean;
  surface?: number;
  constructionYear?: number;
  priceRange?: PriceRanges;
  priceRangeTranslationKey?: string;
  state?: AssetStates;
  use: AssetUses;
  typology?: AssetTypologies;
  subTypology?: AssetSubTypologies;
  cadastralReference?: string;
  cadastralReferenceContainerBuilding?: string;
  countryThreeLetterISORegionName?: string;
  ineCodProv?: number;
  ineCodMun?: number;
  dgcCodVia?: number;
  ineCodVia?: string;
  censusSectionName?: string;
  streetType?: StreetTypes;
  streetName?: string;
  streetNotExists?: boolean;
  streetNumber?: string;
  block?: string;
  stairs?: string;
  portal?: string;
  floorType?: FloorTypes;
  gate?: string;
  locality?: string;
  province?: string;
  postalCode?: string;
  country?: string;
  fullAddressGeneratedName?: string;
  addressGeneratedStartingByProvince?: string;
  addressGeneratedName?: string;
  latitude?: number;
  longitude?: number;
  idufir?: string;
  registeredPropertyNumber?: string;
  propertyRegisterGeoSmartaId?: string;
  propertyRegisterName?: string;
  propertyRegister?: ApiPropertyRegister;
  volume?: string;
  book?: string;
  paper?: string;
  section?: string;
  inscription?: string;
  totalWithoutTaxes?: number;
  totalWithTaxes?: number;
  discountPercentage?: number;
  discountPercent?: number;
  totalWithoutTaxesWithDiscount?: number;
  minimumCalculatedFee?: number;
  ineCodProvStr?: string;
  ineCodMunStr?: string;
  ineCodLocality?: number;
  ineCodLocalityStr?: string;
  isUsedInAppraisal?: boolean;
  plotCode?: string;
  polygonCode?: string;
  /**@todo ADDED MANUALLY. Review all models and components related with this model */
  placeName?: number;
  regionName?: number;

  //front
  tempId?: string;
}

export interface OrderRequestEditModel {
  id: number;
  parentId: number;
  workflowItemLicensees: WorkflowItemLicenseeIndexModel[];
  appraiserPartnerWillDoReport: boolean;
  appraiserPartnerWillVisit: boolean;
  canBeLaunchedInExchange: boolean;
  commercialFeePercentage: number;
  externalRecordId: string;
  feeOption: FeeOptions;
  invoiceAddress: string;
  invoiceEmail: string;
  invoiceFirstName: string;
  invoiceIsEnterprise: boolean;
  automaticInvoicing: boolean;
  invoiceLastName: string;
  invoiceLocality: string;
  invoiceNationalIdentityDocument: string;
  invoicePostalCode: string;
  invoiceProvince: string;
  observations: string;
  opportunityId: string;
  orderType: AppraisalOrderTypes;
  ownerEmail: string;
  ownerFirstName: string;
  ownerFirstNameSearchPattern: string;
  ownerIsEnterprise: boolean;
  ownerLastName: string;
  ownerLastNameSearchPattern: string;
  ownerNationalIdentityDocument: string;
  ownerPhoneNumber: string;
  ownerEntityType: EntityTypes;
  paymentOption: PaymentOptions;
  provision_AppraiserFee: number;
  provision_CommercialFee: number;
  provision_OtherExpenses: number;
  provision_OtherExpensesRappel: number;
  provision_OtherExpensesSimpleNote: number;
  provision_SalePrice: number;
  provision_FeesAndPublicDocuments: number;
  purpose: AppraisalPurposes;
  requestElements: OrderRequestElementEditModel[];
  technicalFeePercentage: number;
  visitEmail: string;
  visitName: string;
  visitObservations: string;
  visitPhone: string;
  visitProposals: VisitProposalDataModel[];
  visitType: AppraisalVisitTypes;
  withEco8052003: boolean;
  appraisalVinculation: AppraisalVinculations;
  appraisalVinculationParentId: number;
  vinculationType: VinculationTypes;
  externalVinculationId: string;
  reportType: ReportTypes;
}

export interface OrderRequestPriceEditModel {
  requestElements: OrderRequestElementEditModel[];
  promotionalCodeId: number | null;
  feeOption: FeeOptions | null;
  provision_OtherExpensesSimpleNote: number | null;
  reportType: ReportTypes;
}

export interface OrderRequestElementEditModel {
  id: number;
  occupationState: OccupationStates;
  buildingState: BuildingStates;
  publicProtection: PublicProtections;
  simpleNoteRequest: boolean;
  surface: number;
  constructionYear: number;
  priceRange: PriceRanges;
  fullAddressGeneratedName: string;
  totalWithoutTaxes: number;
  totalWithTaxes: number;
  discountPercentage: number;
  discountPercent: number;
  totalWithoutTaxesWithDiscount: number;
  minimumCalculatedFee: number;
  latitude: number;
  longitude: number;
  state: AssetStates;
  use: AssetUses;
  typology: AssetTypologies;
  subTypology: AssetSubTypologies;
  cadastralReference: string;
  cadastralReferenceContainerBuilding: string;
  idufir: string;
  registeredPropertyNumber: string;
  propertyRegisterGeoSmartaId: string;
  volume: string;
  book: string;
  paper: string;
  section: string;
  inscription: string;
  countryThreeLetterISORegionName: string;
  ineCodProv: number;
  ineCodMun: number;
  ineCodLocality: number;
  dgcCodVia: number;
  ineCodVia: string;
  streetType: StreetTypes;
  streetName: string;
  streetNumber: string;
  block: string;
  stairs: string;
  portal: string;
  floorType: FloorTypes;
  gate: string;
  locality: string;
  province: string;
  postalCode: string;
  country: string;
  polygonCode: string;
  plotCode: string;
  reportType: ReportTypes;
}

export interface OrderRequestPriceModel {
  totalTaxes: number;
  totalWithoutTaxes: number;
  totalWithTaxes: number;
  totalWithoutTaxesWithDiscount: number;
  totalWithTaxesWithoutDiscount: number;
  requestElements: OrderRequestElementModel[];
  provision_OtherExpensesSimpleNote: number;
}

export interface OrderRequestFileIndexModel {
  fileId: number;
  workflowItemId: number;
  fileName: string;
  filePath: string;
  fileWebPath: string;
  addedDateTime: string;
  addedUserId: number;
}

export interface OrderRequestPaymentIndexModel {
  id: number;
  state: PaymentStates;
  description: string;
  amount: number;
  addedDateTime: string;
  stateLastUpdateDatetime: string;
  redsysPaymentId: string;
  redsysResponseState: string;
}

export interface OrderRequestProvisionEditModel {
  provision_SalePrice?: number;
  provision_AppraiserFee: number;
  provision_CommercialFee: number;
  provision_OtherExpenses: number;
  provision_OtherExpensesRappel: number;
  provision_OtherExpensesSimpleNote: number;
  provision_FeesAndPublicDocuments: number;
  withOpportunityId: boolean;
  isCustomerPendingConfirmEntityType: boolean;
}
