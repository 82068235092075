import { AssetTabs, FoxeetItemValidationTypes } from '../enums';

export interface ValidationReportModel {
  reportValidations: ValidationModel[];
  assetValidations: AssetValidationModel[];
}

export interface ValidationModel {
  related: string;
  relatedId: number;
  id: number;
  addedDateTime: Date;
  description: string;
  validationType: FoxeetItemValidationTypes;
}

export interface AssetValidationModel extends ValidationModel {
  tab: AssetTabs;
}

export interface FoxeetValidationFilterModel {
  summary: string;
  activatedFiltersCount: number;
  orderFieldName: string;
  orderFieldAsc?: boolean;
  id: number;
  ids: number[];
  relatedId?: number;
  relatedIds: number[];
}

export interface AppraisalValidationAssetChangeModel {
  id: number;
  visitFinishDateTime: Date;
  validationDateTime: Date;
}
