import { AssetStates, AssetSurfaceIds, BuildingUses, GroundRepercussionCalculateMethods } from '../enums';
import { FoxeetKeyValueModel } from './extra-information.model';
import { ValorationDynamicResidualMethodModel } from './valoration-dynamic-residual-method.model';
import { ValorationLegalMaximumMethodModel } from './valoration-legal-maximum-method.model';

export interface ValorationCostMethodSumModel {
  sumRestGroundTotalPrice: number;
  sumRestValorationLegalMaximumLimitationGroundTotalValue: number;
}

////////////////////////////////////
// Nuevos modelos

export interface ValorationCostMethodModel {
  groundRepercussionPlotSurface: number;
  groundRepercussionPlotUnitValue: number;
  dynamicResidualTempId: string;
  id: number;
  assetId: number;
  clusterId: number;
  buildingElementId: number;
  totalAdoptedSurface: number;
  assetSurfaceId: AssetSurfaceIds;
  depreciationFunctionalAdoptedTotalValue: number;
  depreciationFunctionalAdoptedUnitValue: number;
  groundRepercussionAdoptedUnitValue: number;
  groundRepercussionAdoptedTotalValue: number;
  groundRepercussionCalculateMethod: GroundRepercussionCalculateMethods;
  observations: string;
  buildeable: number;
  numberOfElementsToBuild: number;
  groundValueUnitPrice: number;
  groundValueTotalPrice: number;
  repercussionUnitPrice: number;
  repercussionTotalPrice: number;
  groundNecessaryExpensesPercentage: number;
  groundNecessaryExpensesUnitPrice: number;
  groundNecessaryExpensesTotalPrice: number;
  groundTotalPrice: number;
  valorationLegalMaximumLimitationGroundTotalValue: number;
  sumRestGroundTotalPrice: number;
  sumRestValorationLegalMaximumLimitationGroundTotalValue: number;
  groundTotalPriceToUse: number;
  groundObservations: string;
  constructionExpensesObservations: string;
  constructionUnitCost: number;
  constructionTotalCost: number;
  necessaryConstructionExpensesPercentage: number;
  necessaryConstructionExpensesUnitPrice: number;
  necessaryConstructionExpensesTotalPrice: number;
  vrbUnitPrice: number;
  vrbTotalPrice: number;
  depreciationPhysicsBuildingPercentage: number;
  depreciationPhysicsBuildingPercent: number;
  depreciationPhysicsBuildingUnitPrice: number;
  depreciationPhysicsBuildingTotalPrice: number;
  depreciationPhysicsElementPercentage: number;
  depreciationPhysicsElementPercent: number;
  depreciationPhysicsElementUnitPrice: number;
  depreciationPhysicsElementTotalPrice: number;
  depreciationPhysicsCustomValueObservations: string;
  manualDepreciationEditing: boolean;
  vrnUnitPrice: number;
  vrnTotalPrice: number;
  staticAbriddedResidual_EditAutomaticValues: boolean;
  staticAbriddedResidual_ComparisonMethodTotalValue: number;
  staticAbriddedResidual_IsCompleted: boolean;
  staticAbriddedResidual_MarketValueUnitPrice: number;
  staticAbriddedResidual_ConstructionCostsUnitPrice: number;
  staticAbriddedResidual_ConstructionExpensesUnitPrice: number;
  staticAbriddedResidual_FinancialExpensesPercentage: number;
  staticAbriddedResidual_MarketingExpensesPercentage: number;
  staticAbriddedResidual_DeveloperProfitPercentage: number;
  staticAbriddedResidual_KCoeficient: number;
  staticAbriddedResidual_Observations: string;
  staticAbriddedResidual_Total: number;
  legalMaximumMethod: ValorationLegalMaximumMethodModel;
  reCalculateLegalMaximumMethod: boolean;
  buildingConstructionYearToUse: number;
  buildingUse: BuildingUses;
  isFutureValoration: boolean;
  comparisonRelatedValorationId: number;

  unfinishedConstruction_UnitCost: number;
  unfinishedConstruction_TotalProgressPercentage: number;
  unfinishedConstruction_ManualExpensesProgressPercentageEditing: boolean;
  unfinishedConstruction_NecessaryExpensesPercentage: number;
  unfinishedConstruction_ExpensesProgressPercentage: number;
  unfinishedConstruction_Observations: string;
  assetState: AssetStates;
  valoration?: any;
  isMigrationValoration: boolean;
  keyValues: FoxeetKeyValueModel[];
}
