import { WorkflowItemLogItemActions, WorkflowItemLogItemTypes } from '../enums';

export interface AppraisalLogItemIndexModel {
  id: number;
  workflowItemId: number;
  workflowStep: number;
  addedDateTime: string;
  addedByUserId: number;
  logItemType: WorkflowItemLogItemTypes;
  logItemSubType: number;
  logItemAction: WorkflowItemLogItemActions;
  description: string;
  // loggedByUser:	IUserIndexModel
  additionalInformation: string;
}

export interface AppraisalLogItemFilterModel {
  appraisalId?: number;
  activatedFiltersCount?: number;
  logItemType?: WorkflowItemLogItemTypes;
  orderFieldAsc?: boolean;
  orderFieldName?: string;
  summary?: string;
}

// --------- RELATED MODELS -----------

export interface AppraisalExternalNotificationLog {
  isExternalReceivedNotificationSuccessfuly: boolean;
  externalNotificationError: string;
  externalBodyNotification: string;
  externalUrlNotification: string;
  externalNotificationDateTime: string;
}
