import { AssetSurfaceIds, BuildingUses } from '../enums';
import { FoxeetKeyValueModel } from './extra-information.model';
import { AppraisalReportMarketStudyItemModel } from './market-study.model';
import { ValorationComparableModel } from './valoration-comparable-method.model';
export interface ValorationDiscountCashflowMethodModel {
  id: number;
  assetId: number;
  clusterId: number;
  buildingElementId: number;
  totalAdoptedSurface: number;
  assetSurfaceId: AssetSurfaceIds;
  depreciationFunctionalAdoptedTotalValue: number;
  depreciationFunctionalAdoptedUnitValue: number;
  groundRepercussionAdoptedUnitValue: number;
  groundRepercussionAdoptedTotalValue: number;
  observations: string;
  buildeable: number;
  numberOfElementsToBuild: number;
  contractTerminationDate: string;
  contractDurationPending: number;
  marketRent_CoefficientKe: number;
  marketRent_KeUnitValue: number;
  marketRent_IdlenessMonthlyTerm: number;
  marketRent_IdlenessMonthlyTermPercent: number;
  marketRent_LatePaymentMonthlyTerm: number;
  marketRent_LatePaymentMonthlyTermPercent: number;
  marketRent_CapexPercent: number;
  marketRent_CapexAnnualTerm: number;
  marketRent_MonthlyExpensesPercent: number;
  marketRent_MonthlyExpenses: number;
  marketRent_MonthlyNetIncomeUnitValue: number;
  marketRent_MonthlyNetIncomeTotalValue: number;
  marketRent_AnnualNetIncomeTotalValue: number;
  updateRatePercent: number;
  ipcPercent: number;
  capitalGainPercent: number;
  rollbackValue: number;
  monthlyDiscountCashflows: DiscountCashflow[];
  yearlyDiscountCashflows: DiscountCashflow[];
  residualLifeDuration: number;
  comparables: ValorationComparableModel[];
  buildingConstructionYearToUse: number;
  buildingUse: BuildingUses;
  updatedIncomeTotalValue: number;
  updatedReversionValue: number;
  totalValue: number;
  isFutureValoration: boolean;
  homogeneousCoefficientObservations: string;
  regardingOcupationObservations: string;
  marketStudy: AppraisalReportMarketStudyItemModel;
  marketMortgageValueDifferences: string;
  isEditableMarketMortgageValueDifferences: boolean;
  currentPricSaleRental: string;
  isEditableCurrentPricSaleRental: boolean;
  isMigrationValoration: boolean;
  keyValues: FoxeetKeyValueModel[];
}

export interface DiscountCashflow {
  period: number;
  updateFactor: number;
  month: number;
  year: number;
  methodNetIncome: number;
  currentNetIncome: number;
  updatedIncome: number;
}
