import { AssetDataSourceTypes, WorkPlanDocumentationOptions, WorkPlanStateTypes } from '../enums';
import { AssetSurfacesInformationDto, AssetSurfacesInformationModel } from './assets.model';

export interface WorkflowItemReportAssetWorkPlanStateModel {
  id: number;
  assetId: number;
  assetIdentificationDataSource: AssetDataSourceTypes;
  assetWorkPlanStateItems: AssetWorkPlanStateItemDto[];
  duration: number;
  totalBudget: number;
  estimatedFinishDatetime: string;
  totalProgress: number;
  totalAverageWorkCost: number;
  observations: string;
}

export interface AssetWorkPlanStateItemDto {
  name: string;
  workPlanStateType?: WorkPlanStateTypes;
  amount: number;
  percentageOverTotal: number;
  progressPercentageOfItem: number;
  progressPercentageOverTotal: number;
}

export interface AssetWorkPlanStateDistributionDto {
  id: number;
  assetId: number;
  name: string;
  adoptedSurface: number;
  assignedUnitValue: number;
  assignedTotalVale: number;
  surfaces: AssetSurfacesInformationDto;
  adoptedSurfaceId: number;
}

export interface AssetWorkPlanStateDistributionModel {
  id: number; // id de la distribución
  assetId: number;
  name: string;
  adoptedSurface: number;
  adoptedSurfaceId: number;
  assignedUnitValue: number;
  assignedTotalValue: number;
  surfaces: AssetSurfacesInformationModel;
}

export interface AssetWorkPlanStateModel {
  assetWorkPlanStateItems: AssetWorkPlanStateItemDto[];
  workPlanStateDistributions: AssetWorkPlanStateDistributionModel[];
  totalBudget: number;
  estimatedFinishDatetime: string;
  totalProgress: number;
  totalAverageWorkCost: number;
  totalSurface: number;
  observations: string;
  isRecalculationNeeded: boolean;
  basicProject: WorkPlanDocumentationOptions;
  basicProjectRedactionDateTime: string;
  basicProjectVisaDateTime: string;
  currentWorkPlanObservations: string;
  executionProject: WorkPlanDocumentationOptions;
  executionProjectRedactionDateTime: string;
  executionProjectVisaDateTime: string;
  workLicense: WorkPlanDocumentationOptions;
  workLicenseDateTime: string;
  workLicenseExpirationDateTime: string;
  workStartDateTime: string;
  isWorkOnHold: boolean;
  residualDynamicValorationId: number;
  residualDynamicValorationValue: number;
}

export interface CheckSurfacesForWorkPlanModel {
  assets: CheckSurfacesForWorkPlanAssetModel[];
}
export interface CheckSurfacesForWorkPlanAssetModel {
  id: number;
  name: string;
}
