import { FoxeetItemValidationTypes } from '../enums/backend.enums';

export type AlertMessageTypes = FoxeetItemValidationTypes;

export interface AlertData {
  title: string;
  errors: AlertMessage[];
  warnings: AlertMessage[];
  info: AlertMessage[];
  showEmptyParts: boolean;
}

export interface AlertMessage {
  id: number;
  addedDateTime: Date;
  description: string;
  validationType: AlertMessageTypes;
}
