import { UserRoleIndexModel } from './user-roles.model';

export interface TechnicalManagerEditModel {
  id: number;
  email: string;
  phoneNumber: string;
  firstName: string;
  lastName: string;
  password: string;
  confirmPassword: string;
  isActive: boolean;
  requesterCompanyId: number;
  supplierCompanyId: number;
  customerId: number;
  nationalIdentityDocument: string;
  position: string;
  extract: string;
  sendNotificationsByEmail: boolean;
  sendNotificationsByPhone: boolean;
  roles: UserRoleIndexModel[];
}

// REFACTOR

export interface ManagerTechnicalModel {
  id: number;
  phoneNumber: string;
  email: string;
  firstName: string;
  lastName: string;
  profileImageFileName: string;
  profileImageFileWebPath: string;
  isActive: boolean;
  isTemporalPassword: boolean;
  sendNotificationsByEmail: boolean;
  sendNotificationsByPhone: boolean;
  requesterCompanyId: number;
  supplierCompanyId: number;
  customerId: number;
  nationalIdentityDocument: string;
  paymentOptions: number;
  position: string;
  extract: string;
  roles: UserRoleIndexModel[];
}

export interface TechnicalManagerIndexModel {
  id: number;
  email: string;
  firstName: string;
  lastName: string;
  isActive: boolean;
  requesterCompanyId: number;
  supplierCompanyId: number;
  customerId: number;
  nationalIdentityDocument: string;
  appraisalsInProcessCount: number;
  position: string;
  extract: string;
}
