import {
  AppraisalExternalCommunicationProcessStates,
  ExternalCommunicationActions,
  ExternalCommunicationStates,
  ExternalCommunicationTypes,
  LicenseeTypes,
} from '../enums/backend.enums';

export interface AppraisalExternalCommunicationProcessIndexModel extends WorkflowItemExternalCommunicationProcessIndexModel {
  recordId: string;
}

export interface AppraisalExternalCommunicationProcessFilterModel {
  id: number;
  ids: number[];
  workflowItemId: number;
  workflowItemIds: number[];
  relatedItemLicenseeId: number;
  relatedItemLicenseeIdAndWorkflowItemLicenseeType: Map<number, LicenseeTypes[]>;
  addedMaxDateTime: string;
  addedMinDateTime: string;
  finishedDatetime: string;
  lastUpdateDatetime: string;
  actionToCommunicate: ExternalCommunicationActions;
  state: ExternalCommunicationStates;
  communicationType: ExternalCommunicationTypes;
  actionsToCommunicate: ExternalCommunicationActions;
  states: ExternalCommunicationStates[];
  communicationTypes: ExternalCommunicationTypes[];
  finishedMaxDateTime: string;
  finishedMinDateTime: string;
  summary: string;
  activatedFiltersCount: number;
  orderFieldName: string;
  orderFieldAsc: boolean;
  recordId: string;
  externalRecordId: string;
  externalRecordIds: string[];
  relatedItemUserId: number;
  relatedItemWorkerRoleAndUserIds: number[];
}

export interface WorkflowItemExternalCommunicationProcessIndexModel {
  addedDateTime: string;
  id: number;
  workflowItemId: number;
  state: ExternalCommunicationStates;
  lastUpdateDateTime: string;
  communicationType: ExternalCommunicationTypes;
  requesterCompany: string;
  lastUpdatedUser: string;
  action: ExternalCommunicationActions;
  communicationTypeId: number;
  isFinished: boolean;
  isKoChecked: boolean;
  koCheckedDescription: string;
  externalRecordId: string;
  technicalManager: string;
}

export interface WorkflowItemExternalCommunicationProcessFilterModel {
  id: number;
  ids: number[];
  workflowItemId: number;
  workflowItemIds: number[];
  relatedItemLicenseeId: number;
  relatedItemLicenseeIdAndWorkflowItemLicenseeType: Map<number, LicenseeTypes[]>;
  addedMaxDateTime: string;
  addedMinDateTime: string;
  finishedDatetime: string;
  lastUpdateDatetime: string;
  actionToCommunicate: ExternalCommunicationActions;
  state: ExternalCommunicationStates;
  communicationType: ExternalCommunicationTypes;
  actionsToCommunicate: ExternalCommunicationActions;
  states: ExternalCommunicationStates[];
  communicationTypes: ExternalCommunicationTypes[];
  finishedMaxDateTime: string;
  finishedMinDateTime: string;
  summary: string;
  activatedFiltersCount: number;
  orderFieldName: string;
  orderFieldAsc: boolean;
  statesExcluded: ExternalCommunicationStates[] | null;
  stateExcluded: ExternalCommunicationStates;
  isKoChecked: boolean;
  isFinished: boolean;
  appraisalExternalCommunicationProcessState: AppraisalExternalCommunicationProcessStates | null;
}

export interface WorkflowItemExternalCommunicationProcessEventModel {
  id: number;
  communicationProcessId: number;
  addedDateTime: string;
  body: string;
  url: string;
  fileName: string;
  wasSuccessful: boolean;
  responseMessage: string;
  addedUserFullName: string;
  checkedDateTime: any;
  checkedUserFullName: string;
  checkedUserId: number;
}

export interface WorkflowItemExternalCommunicationProcessModel {
  id: number;
  workflowItemId: number;
  state: ExternalCommunicationStates;
  lastUpdateDateTime: string;
  communicationType: ExternalCommunicationTypes;
  requesterCompany: string;
  lastUpdatedUser: string;
  action: ExternalCommunicationActions;
  recordId?: string;
  communicationTypeId: number;
  isFinished: boolean;
  isKoChecked: boolean;
  koCheckedDescription: string;
}

export interface WorkflowItemExternalCommunicationProcessModelExtended extends WorkflowItemExternalCommunicationProcessModel {
  isOpen: boolean;
  status: ExternalCommunicationStatus;
}

export enum ExternalCommunicationStatus {
  IN_PROCESS,
  SUCCESS,
  ERROR,
}
