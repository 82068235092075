import { LicenseeLoginResponseModel } from './license.model';

export interface UserLoginModel {
  isTemporalPassword: boolean;
  expires: Date;
  issued: Date;
  access_token: string;
  degree: string;
  firstName: string;
  email: string;
  lastName: string;
  position: string;
  token_type: string;
  userName: string;
  roles: number[];
}

export interface AccessToken {
  token: string;
  expiresIn: number;
}

export interface LoginResponseModel {
  accessToken: AccessToken;
  refreshToken: string;
  userId: number;
  userName: string;
  email: string;
  phoneNumber: string;
  firstName: string;
  lastName: string;
  degree: string;
  position: string;
  isTemporalPassword: boolean;
  profileImagePath: string;
  licenses: LicenseeLoginResponseModel[];
  isActive: boolean;
  /**
   * Sólo de IVForce
   */
  isIndividualCustomer: boolean;
  isNecessaryToAcceptIncompatibilityConcract: boolean;
  /**
   * Sólo de Tasafy
   */
  haveToShowWelcomePopup: boolean;
  sharingId: string;
  haveAcceptedCookies: boolean;
  acceptedCookiesDateTime: any;

  // Internationalization
  cultureCodeCurrency: string;
  cultureCodeLanguage: string;
}
