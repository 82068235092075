export function downloadExcel(blob: Blob, title?: string) {
  const fileName = `${title?.trim()}_${new Date().toLocaleDateString().split('/').join('_')}`;
  downloadFile(blob, fileName);
}

export function downloadDocument(blob: Blob, fileName: string) {
  const uri = window.URL.createObjectURL(blob);
  window.open(uri, fileName, 'toolbar=1,location=1,directories=1,status=1,menubar=1,scrollbars=1,resizable=1');
}

export const downloadFile = (blob: Blob, fileName: string) => {
  const url = window.URL.createObjectURL(blob);
  downloadUrlContent(url, fileName);
};

export const downloadUrlContent = (url: string, fileName?: string) => {
  const a = document.createElement('a');
  a.setAttribute('style', 'diplay: none');
  document.body.appendChild(a);
  a.href = url;
  if (fileName) a.download = fileName;
  a.click();
  setTimeout(() => {
    window.URL.revokeObjectURL(url);
    document.body.removeChild(a);
  }, 1000);
};
