export interface AppraisalRevisionIndexModel {
  id: number;
  workflowItemId: number;
  addedDateTime: string;
  addedUserId: number;
  isSolved: boolean;
  solvedDateTime: string;
  solvedUserId: number;
  reasonDescription: string;
  solutionDescription: string;
  addedUserFullName: string;
  solvedUserFullName: string;
  // workflowItem: IWorkflowItemIndexModel;
}

export interface AppraisalRevisionEditModel {
  id: number;
  solutionDescription: string;
  workflowItemId: number;
  reasonDescription: string;
}
