export const PATHS = {
  LOGIN: 'login',
  DASHBOARD: 'dashboard',
  USERS: 'users',
  ENTITIES: 'entities',
  PAGE_NOT_FOUND: 'page-not-found',
  REQUESTER_COMPANIES: 'requester-companies',
  CUSTOMERS: 'customers',
  SUPPLIER_COMPANIES: 'supplier-companies',
  PROFESSIONALS: 'professionals',
  APPRAISALS: 'appraisals',
  QUALITY_CONTROL: 'quality-control',
  REQUESTS: 'requests',
  MANAGEMENT_ORDERS: 'management-orders',
  SIMPLE_NOTES: 'simple-notes',
  ORDERS: 'orders',
  WIKI: 'wiki',
  ACTIVES: 'actives',
  PARTICIPANTS: 'participants',
  INVOICES: 'invoices',
  USERS_CREDENTIALS: 'users-credentials',
  PAYMENT_SUMMARY: 'payment-summary',
  CODE_MANAGEMENT: 'code-management',
  FINANCIAL: 'financial',
  PROCESS_MANAGER_USER: 'process-manager-user',
  TECHNICAL_MANAGER_USER: 'technical-manager-user',
  COMMERCIAL_MANAGER_USER: 'commercial-manager-user',
  INCOMPATIBILITIES: 'incompatibilities',
  ACCOUNT_MANAGEMENT: 'account-management',
  MY_PROFILE: 'my-profile',
  ACADEMY: 'academy',
  ADMIN: 'admin',
  MY_WORK: 'my_work',
  CHANGE_TEMPORAL_PASSWORD: 'change-temporal-password',
  REQUIRE_PASSWORD_CHANGE: 'require-password-change',
  PASSWORD_RESTORE: 'login/restore',
  ACCOUNT_ACTIVATION: 'activation',
  EXTERNAL_CONNECTIONS: 'external-connections',
  MARKET_STUDY: 'market-study',
};

export enum SUB_PATHS {
  INCIDENTS = 'incidents',
  LIST = 'list',
  ADD = 'add',
  CLAIMS = 'claims',
  COMMUNICATIONS = 'communications',
  DETAIL = 'detail',
  INFORMATION = 'information',
  PROFILE = 'profile',
  BILLING_INFO = 'billing-info',
  DOCUMENTS = 'documents',
  MESSAGE_BOARD = 'message-board',
  REGISTRAL_PLAT = 'registral-plat',
  REVISIONS = 'revisions',
  REPORT = 'report',
  REPORTS = 'reports',
  MASSIVE = 'massive',
  INVOICING = 'invoicing',
  INVOICES = 'invoices',
  PORTFOLIOS = 'portfolios',
  DELIVERABLES = 'deliverables',
  APPRAISALS = 'appraisals',
  COLLABORATOR = 'collaborator',
  USERS = 'users',
  SCHEDULE = 'schedule',
  INCOMPATIBILITIES = 'incompatibilities',
  PREFERENCES = 'preferences',
  IDENTIFICATION = 'identification',
  LOCATION = 'location',
  TYPOLOGY = 'typology',
  SURFACES = 'surfaces',
  PROPERTY_FEATURES = 'propertyfeatures',
  LOCATION_AND_SURROUNDINGS = 'locationAndSurroundings',
  LAND_DESCRIPTION = 'landDescription',
  BUILDING_INFO = 'buildingInfo',
  PROPERTY_CHARACTERISTICS = 'propertyCharacteristics',
  TERRAIN_FEATURES = 'terrainFeatures',
  URBANISTIC_SITUATION = 'urbanisticSituation',
  URBANISTIC_SITUATION_TERRAIN = 'urbanisticSituationTerrain',
  ASSET_LOG_CHANGES = 'assetLogChanges',
  ASSET_EXTRA_INFORMATION = 'assetExtraInformation',
  TENURES = 'tenures',
  CHECK_VALIDATIONS = 'checkValidations',
  VALUATIONS = 'valuations',
  COMPARISON_METHOD = 'comparison-method',
  NEW = 'new',
  COST_METHOD = 'cost-method',
  DISCOUNT_CASHFLOW_METHOD = 'discount-cashflow-method',
  CONTRACT_CASHFLOW_METHOD = 'contract-cashflow-method',
  LEGAL_MAXIMUM_METHOD = 'legal-maximum-method',
  OTHER_METHOD = 'other-method',
  DYNAMIC_RESIDUAL_METHOD = 'dynamic-residual-method',
  STATIC_RESIDUAL_METHOD = 'static-residual-method',
  ECONOMIC_EXPLOITATION_METHOD = 'economic-exploitation-method',
  RUSTIC_ESTATE_EXPLOITATION_METHOD = 'rustic-estate-exploitation-method',
  CRAWLED_METHOD = 'crawled-method',
  CONDITIONINGS = 'conditionings',
  PHOTOS = 'photos',
  APPRAISAL_ASSET_LOG_CHANGES = 'appraisalAssetLogChanges',
  COMMON_ASSETS = 'commonAssets',
  CLUSTERS = 'clusters',
  DETAILS = 'details',
  TERRAIN_CLUSTERS = 'terrain-clusters',
  BUILDING_ELEMENTS = 'buildingElements',
  ASSETS = 'assets',
  HISTORY = 'history',
  PAYMENT_HISTORY = 'payment-history',
  BUILDING_STATE = 'buildingState',
  BILLING = 'billing',
  APPRAISAL_RELATIONSHIP = 'appraisal-relationship',
  PAYMENT = 'payment',
  LICENSE_DETAIL = 'license',
  REPORT_CUSTOMIZATION = 'report-customization',
  COURSES = 'courses',
  WELCOME = 'welcome',
  BASIC_CONCEPTS = 'basicConcepts',
  APPRAISAL_EXECUTION = 'appraisalExecution',
  ALLOWED_ROLES = 'allowed-roles',
  CONNECTIONS_NETWORK = 'connections-network',
  BACKTESTING = 'backtesting',
  SEND_TO_CLIENT = 'deliver-to-client',
  EXPLOITATION_DESCRIPTION = 'exploitation-description',
  RUSTIC_ESTATE_FEATURES = 'rustic-estate-features',
  RECORDS = 'records',
  INVOICE_CUSTOMIZATION = 'invoice-customization',
  APPRAISAL_PRECEDENTS = 'precedents',
  MULTIPLE_EDITION = 'multiple-edition',
  // EXAMPLE = 'example',
  MARKET_STUDIES = 'market-studies',
  COMPARABLES = 'comparables',
  TRAINING = 'training',
  MANAGEMENT_ORDERS = 'management-orders',
}
