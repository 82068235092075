import { ConditionalSectionTypes, ConditionalTypes, CondWarnObserTypes, ObservationSectionTypes, ObservationTypes, WarningSectionTypes, WarningTypes } from '../enums';
import { ConditionWarningEnumModel } from './enums.models';

export interface AssetConditionalsWarningsObservationsItemModel {
  conditions: ConditionsItemModel[];
  warnings: WarningsItemModel[];
  observations: ObservationsItemModel[];
}

export interface ItemModel<T> {
  sectionType: T;
  sectionTranslateKey: string;
  itemList: AssetConditionalsWarningsObservationsItemInformationOfConditionalTypes[];
}

export type ConditionsItemModel = ItemModel<ConditionalSectionTypes>;
export type WarningsItemModel = ItemModel<WarningSectionTypes>;
export type ObservationsItemModel = ItemModel<ObservationSectionTypes>;
export type SectionItemModel = AssetConditionalsWarningsObservationsItemInformationOfConditionalTypes;

export interface AssetConditionalsWarningsObservationsItemInformationOfConditionalTypes {
  id: number;
  type: number;
  description: string;
  unique: boolean;
  editable: boolean;
}

export interface AssetConditionalsWarningsObservationsEditModel {
  id: number; // Id del item (no del asset)
  condWarnObserType: CondWarnObserTypes;
  description: string;
  /**
   * Estos campos deberían ser nullables en back, cada item sólo pertenecea un tipo condicional | advertencia | observaciones
   */
  conditionalType: ConditionalTypes | null;
  warningType: WarningTypes | null;
  observationType: ObservationTypes | null;
}

// FRONT MODELS

export interface ListTypesModel {
  conditions: ListTypeItemModel;
  warnings: ListTypeItemModel;
  observations: ListTypeItemModel;
}

export interface ListTypeItemModel {
  data: ConditionWarningEnumModel[];
  type: CondWarnObserTypes;
}
