import { registerLocaleData } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import localeEn from '@angular/common/locales/en';
import localeEs from '@angular/common/locales/es';
import localePt from '@angular/common/locales/pt';
import { Inject, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MAT_DATE_LOCALE, MatNativeDateModule } from '@angular/material/core';
import { MatIconRegistry } from '@angular/material/icon';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { BrowserModule, DomSanitizer } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CoreModule } from '@foxeet/core';
import { BACK_CONFIG, BackConfig, DataAccessModule, LangJsonTranslatesService } from '@foxeet/data-access';
import { NewCoreModule } from '@foxeet/n-core';
import { ConfigLoaderModule, ErrorHandlerModule, I18nModule } from '@foxeet/utils-modules';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { PrimeNGConfig } from 'primeng/api';
import { enLang, esLang, ptLang } from '../assets/i18n';
import { authoritiesDefinition } from '../config/authorities';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MatPaginatorIntlCustomService } from '@foxeet/utils/services/mat-paginator-intl-custom.service';
import { ToastrWrapperService } from '@foxeet/utils/services';
import { BehaviorSubject, merge } from 'rxjs';
import { CultureChestModule, CultureSelectors } from '@foxeet/chests/culture';
import { LocationChestModule } from '@foxeet/chests/location';
import { UtilsChestModule } from '@foxeet/chests/utils-chests';
import { TypologyChestModule } from '@foxeet/chests/typology';
import { MarketStudyChestModule } from '@foxeet/chests/market-study';
import { retry } from 'rxjs/operators';
import { LOGGER_CONFIG, LoggerService } from '@foxeet/utils/services/logger.service';
import { SignalrService } from '@foxeet/feature/auth';
import { LanguageSelectorList, TranslationModel } from '@foxeet/domain';
import { AVAILABLE_LANGUAGES } from '../config/language-selector.config';
import { FullCalendarModule } from '@fullcalendar/angular';

const chests = [CultureChestModule, LocationChestModule, UtilsChestModule, TypologyChestModule, MarketStudyChestModule];

/**
 * BACK CONFIGURATION
 */

const DEFAULT_LANG_FILE = esLang;

const backConfig: BackConfig = {
  baseUrl: new BehaviorSubject<string>(environment.defaultEndpoint),
  lang: new BehaviorSubject<string>(esLang.languageKey),
  platform: environment.platform,
};

const LANGUAGES_FILES = [esLang, enLang, ptLang];

registerLocaleData(localeEs, 'es');
registerLocaleData(localePt, 'pt');
registerLocaleData(localeEn, 'en');

@NgModule({
  declarations: [AppComponent],
  imports: [
    chests,
    BrowserModule,
    AppRoutingModule,
    ConfigLoaderModule.forRoot<LanguageSelectorList>({
      environment,
      authorities: authoritiesDefinition,
      languagesConfig: { defaultLanguage: esLang.languageKey, availableLanguages: AVAILABLE_LANGUAGES },
    }),
    CoreModule,
    I18nModule.forRoot(),
    DataAccessModule,
    FormsModule,
    MatNativeDateModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    HttpClientModule,
    ErrorHandlerModule,
    NewCoreModule,
    FullCalendarModule,
  ],
  providers: [
    {
      provide: LOGGER_CONFIG,
      useValue: { isEnabled: !environment.production, isProduction: environment.production },
    },
    {
      provide: BACK_CONFIG,
      useValue: backConfig,
    },
    { provide: MAT_DATE_LOCALE, useValue: 'es-ES' },
    { provide: MatPaginatorIntl, useClass: MatPaginatorIntlCustomService },
    ToastrWrapperService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(
    private _translateService: TranslateService,
    private _matIconRegistry: MatIconRegistry,
    private _domSanitizer: DomSanitizer,
    private _langJsonTranslatesService: LangJsonTranslatesService,
    private readonly store: Store,
    private config: PrimeNGConfig,
    private logger: LoggerService,
    private signalr: SignalrService,
    @Inject(BACK_CONFIG) private backConfig: BackConfig,
  ) {
    this._getTranslations();
    this._addImagesToMaterialIconRegistry();
    this.store.select(CultureSelectors.LanguageCode).subscribe((langCode) => {
      const lang = AVAILABLE_LANGUAGES.find((l) => l.cultureCodeLanguage === langCode)?.lang;
      this.backConfig.lang.next(lang || langCode);
    });
    merge(this.signalr.onJoinRoomEvent, this.signalr.onLeaveRoomEvent, this.signalr.onConnectionEvent).subscribe((event) => {
      if (event.error) {
        this.logger.error(event.message, event.error);
      } else {
        this.logger.info(event.message);
      }
    });
  }

  private _addImagesToMaterialIconRegistry(): void {
    this._matIconRegistry.addSvgIcon('flag-spain', this._domSanitizer.bypassSecurityTrustResourceUrl('./assets/images/flags/016-spain.svg'));
    this._matIconRegistry.addSvgIcon('flag-uk', this._domSanitizer.bypassSecurityTrustResourceUrl('./assets/images/flags/012-uk.svg'));
    this._matIconRegistry.addSvgIcon('flag-portugal', this._domSanitizer.bypassSecurityTrustResourceUrl('./assets/images/flags/005-portugal.svg'));
  }

  private _getTranslations(): void {
    LANGUAGES_FILES.forEach(({ languageKey }) => this._getLanguageTranslates(languageKey));
    this._translateService.addLangs(LANGUAGES_FILES.map((language) => language.languageKey));
    LANGUAGES_FILES.forEach((language) => this._translateService.setTranslation(language.languageKey, language.translates, true));
    this._translateService.use(DEFAULT_LANG_FILE.languageKey);
    this._translateService.get('primeng').subscribe((res) => this.config.setTranslation(res));
  }

  private _getLanguageTranslates(languageKey): void {
    this._langJsonTranslatesService
      .getTranslations(languageKey)
      .pipe(retry(3))
      .subscribe((body: TranslationModel) => this._translateService.setTranslation(languageKey, body?.data, true));
  }
}
