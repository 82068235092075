import { FeeOptions, PaymentOptions } from '../enums';

export interface OrderRequestInvoiceEditModel {
  id: number;
  orderRequestId: number;
  appraisalIds: number[];
  totalValue: number;
}

export interface OrderRequestInvoiceIndexModel {
  id: number;
  invoiceNumberId: string;
  invoiceDateTime: string;
  ivaTotal: number;
  irpfTotal: number;
  totalWithTaxes: number;
  totalWithoutTaxes: number;
}

export interface OrderRequestInvoiceSummaryLine {
  name: string;
  salePrice: number;
  appraiserFee: number;
  commercialFee: number;
  otherExpenses: number;
  otherExpensesRappel: number;
  otherExpensesSimpleNote: number;
  feesAndPublicDocuments: number;
}

export interface OrderRequestInvoiceSummary {
  id: number;
  paymentOption: PaymentOptions;
  feeOption: FeeOptions;
  totalAppraisalsCount: number;
  totalAppraisalsFinishedCount: number;
  totalAppraisalsInvoicedCount: number;
  isAutomaticInvoice: boolean;
  provisionLine: OrderRequestInvoiceSummaryLine;
  confirmedInvoicedLine: OrderRequestInvoiceSummaryLine;
  confirmedNotInvoicedLine: OrderRequestInvoiceSummaryLine;
  confirmedCancelledLine: OrderRequestInvoiceSummaryLine;
  confirmedTotalsSummary: OrderRequestInvoiceSummaryLine;
}

export interface OrderRequestInvoiceManualModel {
  totalWithoutTaxes: number;
  invoiceDateTime: Date;
  invoiceNumberId: number;
  orderRequestId: number;
  appraisalIds: number[];
}

export interface OrderRequestInvoiceDataEditModel {
  id: number;
  invoiceIsEnterprise: boolean;
  invoiceFirstName: string;
  invoiceLastName: string;
  invoiceNationalIdentityDocument: string;
  invoiceEmail: string;
  invoiceAddress: string;
  invoicePostalCode: string;
  invoiceLocality: string;
  invoiceProvince: string;
}
