import { BatchStates } from '../enums';

export interface AppraisalBatchFilterModel {
  id: number;
  name: string;
  fileName: string;
  addedMaxDateTime: string;
  addedMinDateTime: string;
  batchState: BatchStates;
  processError: string;
  summary: string;
  activatedFiltersCount: number;
  orderFieldName: string;
  orderFieldAsc: boolean;
}

export interface AppraisalBatchIndexModel {
  id: number;
  name: string;
  fileName: string;
  addedDateTime: string;
  addedUserId: number;
  addedUserName: string;
  lastUpdatedDateTime: string;
  lastUpdatedUserId: number;
  batchState: BatchStates;
  summary: string;
  activatedFiltersCount: number;
  orderFieldName: string;
  orderFieldAsc: boolean;
  possibleOrderFieldNames: string[];
}

export interface AppraisalBatchModel {
  id: number;
  name: string;
  fileName: string;
  addedDateTime: string;
  addedUserId: number;
  addedUserName: string;
  lastUpdatedDateTime: string;
  lastUpdatedUserId: number;
  batchState: BatchStates;
  summary: string;
  activatedFiltersCount: number;
  orderFieldName: string;
  orderFieldAsc: boolean;
  possibleOrderFieldNames: string[];
  processError: string;
  fileWebPath?: string;
}
