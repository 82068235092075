import { MediaMatcher } from '@angular/cdk/layout';
import { ChangeDetectorRef, Component, OnDestroy, SecurityContext, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ASYNC_BUTTON_STATE, InfoAlignment, InfoModalComponent, InfoModalModel, InfoTypes, WebAsyncButtonModule } from '@foxeet/ui';
import { CUSTOM_EMAIL } from '@foxeet/utils/forms';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject } from 'rxjs';
import { finalize, take, takeUntil } from 'rxjs/operators';
import { ToastrWrapperService } from '@foxeet/utils/services/toastr-wrapper.service';
import { AuthService } from '../../providers';
import { UnsuscriptionHandler } from '@foxeet/utils/components';
import { BackUrlService } from '@foxeet/data-access';
import { MatDialog } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'feature-auth-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent extends UnsuscriptionHandler implements OnDestroy {
  private _appVersionAlertShowed = false;

  @ViewChild(WebAsyncButtonModule, { static: true }) asyncButtonComponent!: WebAsyncButtonModule;

  private _mobileQueryListener: () => void;
  public mobileQuery: MediaQueryList;

  public form = this._formBuilder.group({
    userName: ['', [Validators.required, Validators.pattern(CUSTOM_EMAIL.pattern)]],
    password: ['', Validators.required],
  });
  public stateLoginBtn = new BehaviorSubject<ASYNC_BUTTON_STATE>(ASYNC_BUTTON_STATE.IDLE);

  constructor(
    private _router: Router,
    private _media: MediaMatcher,
    private _formBuilder: FormBuilder,
    private _authService: AuthService,
    private _translateService: TranslateService,
    private _changeDetectorRef: ChangeDetectorRef,
    private _toastrWrapperService: ToastrWrapperService,
    private _backUrlService: BackUrlService,
    private _matDialog: MatDialog,
    private _domSanitizer: DomSanitizer,
  ) {
    super();
    this._checkIfShowAppVersionAlert();
    this.mobileQuery = this._media.matchMedia('(max-width: 780px)');
    this._mobileQueryListener = () => this._changeDetectorRef.detectChanges();
    this.mobileQuery.addEventListener('mobileQueryMatchWidth', this._mobileQueryListener);
  }

  public login(): void {
    this.stateLoginBtn.next(ASYNC_BUTTON_STATE.SENDING);
    const { userName, password } = this.form.controls;

    this._authService
      .login(userName.value, password.value)
      .pipe(
        take(1),
        finalize(() => this.stateLoginBtn.next(ASYNC_BUTTON_STATE.IDLE)),
      )
      .subscribe(
        () => {
          if (this._authService.itIsTemporalPassword()) {
            this._router.navigate(['change-temporal-password']);
          } else {
            const redirectionUrl = this._authService.getAndClearFromUrl() ?? this._authService.getDefaultUrl();
            this._router.navigate([redirectionUrl]).then((isFulFilled) => isFulFilled === false && this._router.navigate([this._authService.getDefaultUrl()]));
          }
        },
        ({ error }) => this._toastrWrapperService.error(error.detail || error.title, error.title),
      );
  }

  private _showAppVersionAlert() {
    const infoModalConfig: InfoModalModel = {
      name: 'reload-app',
      title: 'web_reload_app_title',
      message: this._domSanitizer.sanitize(SecurityContext.HTML, this._translateService.instant('web_reload_app_message')),
      alignment: InfoAlignment.center,
      infoType: InfoTypes.innerHTML,
    };

    this._matDialog.open(InfoModalComponent, { disableClose: true, data: infoModalConfig });
  }

  private _checkIfShowAppVersionAlert() {
    this._backUrlService.showAppVersionAlert$.pipe(takeUntil(this._componentDestroyed)).subscribe((showAlert) => {
      if (showAlert && !this._appVersionAlertShowed) {
        this._appVersionAlertShowed = true;
        this._showAppVersionAlert();
      }
    });
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.mobileQuery.removeEventListener('mobileQueryMatchWidth', this._mobileQueryListener);
  }
}
