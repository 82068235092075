import { FloorTypes, LocationConfirmedByTypes, StreetTypes } from '../enums';

export interface AssetLocalizationInformation {
  addressObservations: string;
  block: string;
  countryThreeLetterISORegionName: string;
  dgcCodVia: number;
  floorType: FloorTypes;
  gate: string;
  ineCodLocality: number;
  ineCodMun: number;
  ineCodProv: number;
  ineCodVia: string;
  latitude: number;
  locality: string;
  locationConfirmedType: LocationConfirmedByTypes;
  locationObservations: string;
  longitude: number;
  portal: string;
  postalCode: string;
  province: string;
  stairs: string;
  streetName: string;
  streetNotExists: boolean;
  streetNumber: string;
  streetType: StreetTypes;
  placeName: string;
  placeCode: string;
  regionName: string;
  polygonCode: string;
  plotCode: string;
  fullAddressGeneratedName: string;
  addressGeneratedStartingByProvince: string;
}

export interface AssetLocalizationInformationModel {
  addressObservations: string;
  block: string;
  countryThreeLetterISORegionName: string;
  dgcCodVia: number;
  floorType: FloorTypes;
  gate: string;
  ineCodLocality: number;
  ineCodMun: number;
  ineCodProv: number;
  ineCodVia: string;
  latitude: number;
  locality: string;
  locationConfirmedType: LocationConfirmedByTypes;
  locationObservations: string;
  longitude: number;
  portal: string;
  postalCode: string;
  province: string;
  stairs: string;
  streetName: string;
  streetNumber: string;
  streetType: StreetTypes;
  streetNotExists: boolean;
}

export interface AssetLocalizationEditModel {
  id: number;
  assetLocalizationInformation: AssetLocalizationInformationModel;
}

export interface AssetLocalizationInformationDto {
  addressObservations: string | null;
  block: string | null;
  countryThreeLetterISORegionName: string | null;
  dgcCodVia: number | null;
  floorType: FloorTypes | null;
  gate: string | null;
  readonly ineCodLocality: number | null;
  ineCodMun: number | null;
  ineCodProv: number | null;
  ineCodVia: string | null;
  latitude: number | null;
  locality: string | null;
  locationConfirmedType: LocationConfirmedByTypes;
  locationObservations: string | null;
  longitude: number | null;
  portal: string | null;
  postalCode: string | null;
  province: string | null;
  stairs: string | null;
  streetName: string | null;
  streetNotExists: boolean;
  streetNumber: string | null;
  streetType: StreetTypes;
  censusSectionName: string | null;
  country: string | null;
  placeName: string | null;
  placeCode: string | null;
  regionName: string | null;
  polygonCode: string | null;
  plotCode: string | null;
  readonly includeUses: boolean;
  readonly includeTypologies: boolean;
  readonly includeStates: boolean;
  readonly includeIsClustered: boolean;
  readonly includeIsEconomicExploitation: boolean;
  readonly includeHasValorations: boolean;
  // NOT USED IN FRONT
  location: any;
}
