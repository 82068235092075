import { InternationalizationTypes } from '../enums';

export interface DynamicModalModel {
  title: string;
  subTitle?: string;
  internationalizationType?: InternationalizationTypes;
  data?: any;
  cancelButtonText?: string;
  confirmButtonText?: string;
  hideCancelButton?: boolean;
  hideConfirmButton?: boolean;
  callback?: any;
}
