import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { EnumsService } from './API/enums.service';
import { LocationService } from './API/location.service';
import { AccountService } from './API/account.service';
import { BackUrlInterceptorService } from './interceptors/back-url.interceptor';
import { CacheService } from './services/cache.service';
import { AdminService } from './API/admin.service';
import { IncompatibilitiesService } from './API/incompatibilities.service';
import { PortfolioService } from './API/portfolio.service';
import { AppraisalAssetValuationService } from './API/appraisal-asset-valuation.service';
import { AppraisalBatchesService } from './API/appraisal-batches.service';
import { AppraisalClaimsService } from './API/appraisal-claims.service';
import { AppraisalDocumentsService } from './API/appraisal-documents.service';
import { AppraisalExternalCommunicationProcessesService } from './API/appraisal-external-comunication-processes.service';
import { AppraisalIncidentsService } from './API/appraisal-incidents.service';
import { AppraisalReportAssetService } from './API/appraisal-report-asset.service';
import { AppraisalReportAssetFilesService } from './API/appraisal-report-asset-files.service';
import { AppraisalRevisionService } from './API/appraisal-revisión.service';
import { AppraisalsService } from './API/appraisals.service';
import { AppraiserInvoicesService } from './API/appraiser-invoices.service';
import { AssetsService } from './API/assets.service';
import { CadastralService } from './API/cadastral.service';
import { ComparablesService } from './API/comparables.service';
import { ConnectionsService } from './API/connections.service';
import { UsersService } from './API/users.service';
import { LangJsonTranslatesService } from './API/lang-json-translates.service';
import { CustomerService } from './API/customer.service';
import { LicenseService } from './API/license.service';
import { LicensePaymentsService } from './API/license-payments.service';
import { LicenseeReportCustomizeService } from './API/licensee-report-customize.service';
import { RequestInvoicesService } from './API/request-invoices.service';
import { RequestsService } from './API/requests.service';
import { SimpleNotesService } from './API/simple-notes.service';
import { LicenseInvoicesService } from './API/supplayer-company-invoices.service';
import { LicensePaymentMethodsService } from './API/supplier-company-payment-methods.service';
import { ValidationAppraisalRegisteredPropertiesService } from './API/validation-appraisal-registered-properties.service';
import { BackUrlService } from './services/back-url.service';
import { AppraisalReportService } from './API/appraisal-report.service';
import { ValorationBuildingElementService } from './API/terrain-cluster.service';
import { RequestBatchesService } from './API/request-batches.service';
import { ConnectionsLicenseeService } from './API/connections-licensees.service';
import { QualityControlService } from './API/quality-control.service';
import { ManagerCommercialService, ManagerProcessService, ManagerTechnicalService } from './API/managers.service';
import { OrderRequestPromotionalCodeService } from './API/order-request-promotional-code.service';
import { QualityControlDocumentsService } from './API/quality-control-documents.service';
import { SupplierCompaniesService } from './API/supplier-companies.service';
import { RequesterCompaniesService } from './API/requester-companies.service';
import { ProfessionalsListByDistanceService, ProfessionalsService } from './API/professionals.service';
import { RequestExternalCommunicationProcessesService } from './API/request-external-communication-processes.service';
import { ComparablesByDistanceService } from './API/comparables-by-distance.service';
import { MarketStudyReportService } from './API/market-study.service';

export const API_SERVICES = [
  AccountService,
  AdminService,
  AppraisalAssetValuationService,
  AppraisalBatchesService,
  AppraisalClaimsService,
  AppraisalDocumentsService,
  AppraisalExternalCommunicationProcessesService,
  AppraisalReportService,
  AppraisalIncidentsService,
  AppraisalReportAssetService,
  AppraisalReportAssetFilesService,
  AppraisalRevisionService,
  AppraisalsService,
  AppraiserInvoicesService,
  AssetsService,
  CadastralService,
  ComparablesService,
  ConnectionsService,
  ConnectionsLicenseeService,
  CustomerService,
  EnumsService,
  IncompatibilitiesService,
  LangJsonTranslatesService,
  LicenseInvoicesService,
  ManagerCommercialService,
  ManagerTechnicalService,
  ManagerProcessService,
  LicenseService,
  LicensePaymentsService,
  LicensePaymentMethodsService,
  LicenseeReportCustomizeService,
  LocationService,
  PortfolioService,
  QualityControlService,
  RequestInvoicesService,
  RequestBatchesService,
  RequestsService,
  RequestExternalCommunicationProcessesService,
  SimpleNotesService,
  UsersService,
  ValidationAppraisalRegisteredPropertiesService,
  ValorationBuildingElementService,
  OrderRequestPromotionalCodeService,
  QualityControlDocumentsService,
  SupplierCompaniesService,
  RequesterCompaniesService,
  ProfessionalsService,
  ProfessionalsListByDistanceService,
  ComparablesByDistanceService,
  MarketStudyReportService,
];

@NgModule({
  imports: [CommonModule, HttpClientModule],
  providers: [
    CacheService,
    BackUrlService,
    API_SERVICES,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: BackUrlInterceptorService,
      multi: true,
    },
  ],
})
export class DataAccessModule {}
