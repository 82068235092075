export interface trainingModel {
  limitAssignments: boolean;
  editProfessionalPreferences: boolean;
  canResidential: boolean;
  canCommercial: boolean;
  canTertiary: boolean;
  canIndustrial: boolean;
  canAnnex: boolean;
  canRusticEstate: boolean;
  canTerain: boolean;
  canDotational: boolean;
}
