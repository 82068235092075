import { BillingData } from './billing-data.model';

export interface LicenseeBillingDataCustomizeModel {
  id: number;
  billingData: BillingData;
  logoFileName: string;
  logoAddedDateTime: string;
  logoFileWebPath: string;
  // Related fields
  licenseeId: number;
}

export interface LicenseeBillingDataCustomizeEditModel {
  id: number;
  billingData: BillingData;
  // Related fields
  licenseeId: number;
}

export interface UpdateLicenseeBillingDataCustomizeModel {
  licenseeId: number;
  imageFile: File;
  imageWidth?: number;
  imageHeight?: number;
}
