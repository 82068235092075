export enum PatternsErrorKeys {
  BUDGET_NUMBER_MASK_ERROR = 'BUDGET_NUMBER_MASK_ERROR',
  EMAIL_VALIDATION_ERROR = 'EMAIL_VALIDATION_ERROR',
  EMAIL_VALIDATION_ERROR_ALT = 'EMAIL_VALIDATION_ERROR_ALT',
  MAX_LENGTH = 'maxlength',
  MIN_LENGTH = 'minlength',
  PATTERN = 'pattern',
  REQUIRED = 'required',
  MIN = 'min',
  MAX = 'max',
  ALPHANUMERIC = 'alphanumeric',
  EMAIL = 'email',
  CUSTOMER_ALREADY_EXISTS = 'CUSTOMER_ALREADY_EXISTS',
  MAX_ADDITION = 'maxAddition',
  NOT_SAME = 'notSame',
  FORM_VALIDATION_PASSWORD_DOES_NOT_MATCH = 'FORM_VALIDATION_PASSWORD_DOES_NOT_MATCH',
}
