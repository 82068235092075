import { AppraisalIncidentCodes } from '../enums';
import { WorkflowItemIndexModel } from './appraisal.model';

export interface AppraisalIncidentEditModel {
  reasonDescription: string;
  incidentCode: AppraisalIncidentCodes;
  isExternallyReportable?: boolean;
}

// Generally used for lists
export interface AppraisalIncidentModel {
  addedDateTime: string;
  addedUserId: number;
  currentStepValue: number;
  isSolved: boolean;
  solvedDateTime: string;
  solvedUserId: number;
  workflowItemId: number;
  id: number;
  addedUserFullName: string;
  incidentCode: AppraisalIncidentCodes;
  reasonDescription: string;
  recordId: string;
  solutionDescription: string;
  solvedUserFullName: string;
  canSetAsResolved: boolean;
  locality: string;
  executionToolId: string;
  canBeExternallyReportable: boolean;
  externalDescription: string;
  externalInformedUserId?: number;
  externalInformedDateTime?: string;
  externalInformedFullName: string;
}

export interface AppraisalIncidentIndexModel {
  addedDateTime: string;
  addedUserId: number;
  currentStepValue: number;
  isSolved: boolean;
  solvedDateTime: string;
  solvedUserId: number;
  workflowItemId: number;
  id: number;
  workflowItem: WorkflowItemIndexModel;
  incidentCode: AppraisalIncidentCodes;
  recordId: string;
  locality: string;
  executionToolId: string;
}

export interface AppraisalIncidentFilterModel {
  RecordIds: string;
  Summary: string;
  ActivatedFiltersCount: number;
  OrderFieldName: string;
  OrderFieldAsc: boolean;
  PossibleOrderFieldNames: string[];
  IncidentIds: number[];
  IncidentCodes: AppraisalIncidentCodes[];
  WorkflowItemIds: number[];
  WorkflowSteps: number[];
  ExcludedWorkflowSteps: number[];
  AddedMaxDateTime: Date;
  AddedMinDateTime: Date;
  IsSolved: boolean;
  SolvedMaxDateTime: Date;
  SolvedMinDateTime: Date;
}
