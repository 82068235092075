// Generally used for lists
export interface WorkflowItemWallCommentCreateModel {
  Comment: string;
  WorkflowItemWallCommentParentId: number;
  WorkflowItemId: number;
}

// Generally used for lists
export interface WorkflowItemWallCommentEditModel extends WorkflowItemWallCommentCreateModel {
  WorkflowItemWallCommentId: number;
}

// new models
export interface AppraisalWallCommentModel {
  addedDateTime: string;
  addedUserFullName: string;
  addedUserProfileImageFileName: string;
  addedUserId: number;
  comment: string;
  id: number;
  lastUpdatedDateTime: string;
  lastUpdatedUserId: number;
  parentId?: number;
  replies: AppraisalWallCommentModel[];
  workflowItemId: number;
}
