import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { BaseFormComponent } from '../../../base-components';
import { ExtendedSelectItem } from '../form-dropdown.types';

@Component({
  selector: 'ui-form-input-dropdown',
  templateUrl: './form-input-dropdown.component.html',
  styleUrls: ['./form-input-dropdown.component.scss'],
})
export class FormInputDropdownComponent extends BaseFormComponent<any> implements OnInit {
  public optionList: ExtendedSelectItem[] = [];

  /**
   * Option object property to take the label of each dropdown item.
   */
  @Input() optionLabel?: string;

  @Input() optionValue?: string;

  @Input() activeFilter = false;

  @Input() filterBy = 'translatedLabel';

  @Input() activeVirtualScroll = false;

  @Input() showClear = false;

  @Input() set options(options: BehaviorSubject<ExtendedSelectItem[]>) {
    if (options) {
      options.pipe(takeUntil(this._componentDestroyed)).subscribe((opts) => {
        this.optionList = opts?.map((option) => {
          return {
            ...option,
            translatedLabel: option.label ? this._translateService.instant(option.label) : option[this.optionLabel as keyof ExtendedSelectItem],
          };
        });
      });
    }
  }

  @Output() selectedValueChange: EventEmitter<any> = new EventEmitter();

  constructor(private _translateService: TranslateService) {
    super();
  }
}
