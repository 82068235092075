import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { isNil } from '@foxeet/utils/functions';
import { OrUndef } from '@foxeet/domain';

@Pipe({
  name: 'toMonthName',
})
export class ToMonthNamePipe extends DatePipe implements PipeTransform {
  constructor(_translateService: TranslateService) {
    super(_translateService.currentLang);
  }

  override transform(value: Date | string | number, format?: string, timezone?: string, locale?: string): string | null;
  override transform(value: null | undefined, format?: string, timezone?: string, locale?: string): null;
  override transform(value: Date | string | number | null | undefined, format?: string, timezone?: string, locale?: string): string | null {
    let month: OrUndef<number>;
    let date: OrUndef<Date>;

    if (!isNil(value)) {
      if (value instanceof Date) {
        month = value.getMonth();
      } else if (typeof value === 'string') {
        month = Number(value);
      } else {
        month = value;
      }
    }

    if (!isNil(value) && !isNil(month)) {
      date = new Date();
      // date should be setted to 1 before setMonth because there are months with 31, 30 or 29 days.
      date.setDate(1);
      // Backend start months on 1 and javascript starts months on 0.
      date.setMonth(month - 1);
    }

    return super.transform(date, format ?? 'MMMM', timezone, locale);
  }
}
